import { Grid, Typography } from "@mui/material";
import htmlReactParser from "html-react-parser";
import React, { useEffect } from "react";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { MetaContext } from "../../context/MetaContext";
import { TitleAndBackBtn } from "./TitleAndBackBtn";
import { publicTheme } from "../App";
import { useWindowSize } from "usehooks-ts";

type Props = {};

export const Imprint: React.FC<Props> = () => {
    const { setTitle } = React.useContext(CurrentPageContext);
    const { metaWithPhotos } = React.useContext(MetaContext);
    const { width: windowWidth } = useWindowSize();

    useEffect(() => {
        setTitle("imprint");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <TitleAndBackBtn
                position={windowWidth <= 600 ? "relative" : "fixed"}
                titleEl={
                    <Grid
                        item
                        sx={{
                            [publicTheme.breakpoints.down("sm")]: {
                                width: "90%",
                            },
                        }}
                    >
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: "50px!important;",
                                [publicTheme.breakpoints.down("lg")]: {
                                    fontSize: "44px!important;",
                                },
                            }}
                        >
                            IMPRINT &<br />
                            DATA <br />
                            PROTECTION
                        </Typography>
                    </Grid>
                }
                backLink="/"
            />
            <Grid
                container
                sx={{
                    ml: "800px",
                    [publicTheme.breakpoints.between("sm", "lg")]: {
                        ml: "400px",
                    },
                    [publicTheme.breakpoints.down("sm")]: {
                        ml: 0,
                    },
                }}
            >
                <Grid
                    item
                    sx={{
                        zIndex: 100,
                        width: `calc(100% - 800px)`,
                        marginTop: `${windowWidth <= 600 ? "20px" : "25vh"}`,
                        [publicTheme.breakpoints.between("sm", "lg")]: {
                            width: `calc(100% - 400px)`,
                        },
                        [publicTheme.breakpoints.down("sm")]: {
                            width: `calc(100% - 8px)`,
                            mx: 1,
                        },
                    }}
                >
                    {metaWithPhotos?.meta?.imprintText ? htmlReactParser(metaWithPhotos?.meta.imprintText) : null}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sx={{ mb: 0.5, mt: 4, mx: 2 }}>
                    <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                        IMPRINT & DATA PROTECTION
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
