import { Box, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useLayoutEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { MetaContextProvider } from "../context/MetaContext";
import { publicTheme } from "./App";
import { Menu } from "./public/Menu";
import { Helmet } from "react-helmet";

declare const window: Window &
    typeof globalThis & {
        prerenderReady: any;
    };

type Props = {
    component: React.ReactNode;
};

const queryClient = new QueryClient({});

export function Public({ component }: Props) {
    const location = useLocation();
    // Scroll to top if path changes
    useLayoutEffect(() => {
        ReactGA.send("pageview");
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const handleClientStateChange = (newState: any) => {
        console.log("Helmet updated:", newState);

        setTimeout(() => {
            window.prerenderReady = true;
        }, 1000);
    };

    return (
        <ThemeProvider theme={publicTheme}>
            <Helmet onChangeClientState={handleClientStateChange}>
                <meta property="fb:app_id" content="1234567890" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.gerdwaliszewski.eu" />
                <meta property="og:site_name" content="GERD WALISZEWSKI" />
                <meta property="og:description" content="PHOTOGRAPHER" />

                <meta property="og:image" content="https://i.imgur.com/8YHXdVv.png?t=2006" />
                <meta property="og:title" content="GERD WALISZEWSKI" />
            </Helmet>
            <QueryClientProvider client={queryClient}>
                <ParallaxProvider>
                    <MetaContextProvider>
                        <Box
                            className="public"
                            sx={{
                                position: "absolute",
                                minHeight: "100vh",
                                p: 2,
                                [publicTheme.breakpoints.down("sm")]: {
                                    p: 0,
                                },
                            }}
                        >
                            <Menu />
                            {component}
                        </Box>
                    </MetaContextProvider>
                </ParallaxProvider>
            </QueryClientProvider>
        </ThemeProvider>
    );
}
