import { Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { ExhibitionService } from "../../client/generated";
import { configInstance } from "../../config";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { getPathBasedOnWindowWidth } from "../../helpers/getPathBasedOnWindowWidth";
import useThrottledMousePos from "../../hooks/useThrottledMousePos";
import { publicTheme } from "../App";
import { TitleAndBackBtn } from "./TitleAndBackBtn";

type Props = {};

export const ExhibitionsList: React.FC<Props> = () => {
    const { setTitle } = React.useContext(CurrentPageContext);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { width: windowWidth } = useWindowSize();
    const [hoverExhibitionImgUrl, setHoverExhibitionImgUrl] = React.useState<string | null>(null);
    const [mousePos, setMousePos] = React.useState({
        x: 0,
        y: 0,
    });
    const ref = React.useRef(null);
    const handleMousePos = (e: { clientX: any; clientY: any }) => {
        setMousePos({
            x: e.clientX,
            y: e.clientY,
        });
    };

    useThrottledMousePos(handleMousePos, 10);

    const { data: exhibitions } = useQuery({
        queryKey: ["exhibitions"],
        queryFn: async () => {
            setIsLoading(true);

            const res = await ExhibitionService.exhibitionControllerExhibitionList(
                0,
                1000,
                JSON.stringify({ $and: [{ isActive: true }] }),
                JSON.stringify({ index: "ASC" }),
            );

            setTimeout(() => setIsLoading(false), 500);
            return res.result;
        },
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5,
        staleTime: 0,
    });

    useEffect(() => {
        setTitle(`exhibitions`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {/* <Loading isLoading={isLoading} /> */}

            <TitleAndBackBtn
                position="fixed"
                titleEl={
                    <Grid item sx={{ display: "flex", alignContent: "center", flexWrap: "wrap" }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: "50px!important;",
                                [publicTheme.breakpoints.down("lg")]: {
                                    fontSize: "44px!important;",
                                },
                            }}
                        >
                            EXHIBITIONS
                        </Typography>
                    </Grid>
                }
                backLink="/"
            />
            <Grid
                container
                sx={{
                    // mt: 12,
                    minHeight: "calc(100vh)",
                    alignContent: "flex-start",
                    // [publicTheme.breakpoints.down("sm")]: {
                    //     mt: 4,
                    // },
                }}
            >
                {exhibitions
                    ? exhibitions.map((exhibition, i) => (
                          <Grid item key={i}>
                              <Grid container sx={{ width: "100vw" }}>
                                  <Grid
                                      onMouseEnter={() => setHoverExhibitionImgUrl(exhibition.photo?.path ?? null)}
                                      onMouseLeave={() => setHoverExhibitionImgUrl(null)}
                                      item
                                      sx={{
                                          zIndex: 100,
                                          transition: publicTheme.transitions.create("opacity", {
                                              easing: publicTheme.transitions.easing.sharp,
                                              duration: publicTheme.transitions.duration.enteringScreen,
                                          }),

                                          "&:hover": {
                                              opacity: 0.5,

                                              "+ div": {
                                                  opacity: 1,
                                              },
                                          },
                                          width: "80%",
                                          mb: 4,
                                          ml: 19,
                                          mr: 4,
                                          marginTop: i === 0 ? "130px" : 0,
                                          [publicTheme.breakpoints.down("sm")]: {
                                              ml: 1,
                                              mr: 1,
                                          },
                                      }}
                                  >
                                      <Link to={exhibition.url ?? "#"} target="_blank" rel="noopener noreferrer">
                                          <Typography variant="h3">{exhibition.title}</Typography>
                                      </Link>
                                  </Grid>
                                  <Grid
                                      item
                                      sx={{
                                          opacity: 0,
                                          transition: publicTheme.transitions.create("opacity", {
                                              easing: publicTheme.transitions.easing.sharp,
                                              duration: publicTheme.transitions.duration.enteringScreen,
                                          }),
                                      }}
                                  ></Grid>
                              </Grid>
                          </Grid>
                      ))
                    : null}

                {windowWidth > 1300 ? (
                    <motion.img
                        loading="lazy"
                        src={
                            configInstance.urls.storage +
                            getPathBasedOnWindowWidth(hoverExhibitionImgUrl ? hoverExhibitionImgUrl : "", windowWidth)
                        }
                        alt={"alt"}
                        ref={ref}
                        initial={false}
                        animate={{ x: mousePos.x, y: mousePos.y }}
                        transition={{
                            type: "tween",
                        }}
                        style={{
                            position: "absolute",
                            width: "auto",
                            height: "400px",
                            marginLeft: "-50px",
                            marginTop: "-50px",
                            opacity: hoverExhibitionImgUrl ? 0.4 : 0,
                        }}
                    />
                ) : null}
            </Grid>
            <Grid container sx={{ mt: 8 }}>
                <Grid item sx={{ mb: 0.6 }}>
                    <Link to="/imprint">
                        <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                            IMPRINT & DATA PROTECTION
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
