import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import StarIcon from "@mui/icons-material/Star";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Grow,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Snackbar,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { DisplayFormat, EditWorkInput, Photo, Work, WorkService } from "../../../client/generated";
import { configInstance } from "../../../config";
import { CurrentPageContext } from "../../../context/CurrentPageContext";
import { moveArrayEl } from "../../../helpers/moveArrayEl";
import { OrEmptyStrings } from "../../../types";
import { adminTheme } from "../../App";
import { PhotoPreviewBackdrop, PhotoPreviewBackdropState } from "../PhotoPreviewBackdrop";
import { TinyEditor } from "../TinyEditor";

export type PhotosData = {
    [key: string]: {
        index: number;
        isMain: boolean;
        title?: string;
        displayFormat: DisplayFormat;
        isVisibleOnBgButtons?: boolean;
        isVisibleOnMain?: boolean;
    };
};

export type PhotoFilePair = {
    file: File;
    title: string;
    isMain: boolean;
    isVisibleOnBgButtons: boolean;
    displayFormat: DisplayFormat;
    isVisibleOnMain: boolean;
    thumbnailUrl: string;
    photo?: Photo;
};

const defaultEditWorkFormData: Partial<OrEmptyStrings<EditWorkInput>> = {
    descriptionLeft: "",
    descriptionRight: "",
    isActive: true,
    url: "",
    title: "",
    mainPhotoDisplayFormat: "",
};

async function resizeImageToSpecificWidth(file: File, maxWidth: number): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event: ProgressEvent<FileReader>) => {
            const result = event.target?.result;
            if (typeof result !== "string") {
                reject("FileReader did not return a string.");
                return;
            }

            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                if (!ctx) {
                    reject("Unable to get canvas context");
                    return;
                }

                if (img.width > maxWidth) {
                    if (img.width > img.height) {
                        canvas.height = (img.height / img.width) * maxWidth;
                        canvas.width = maxWidth;
                    } else {
                        canvas.width = (img.width / img.height) * maxWidth;
                        canvas.height = maxWidth;
                    }
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    resolve(canvas.toDataURL());
                } else {
                    // If image width is less than or equal to maxWidth, return original
                    resolve(result);
                }
            };

            img.onerror = () => {
                reject("Error in loading image");
            };

            img.src = result;
        };

        reader.onerror = () => {
            reject("Error in reading file");
        };

        reader.readAsDataURL(file);
    });
}

async function photoEntitiesToPhotoFilePairs(
    photos: Photo[],
    setPhotosLoadingState: React.Dispatch<React.SetStateAction<number>>,
): Promise<PhotoFilePair[]> {
    let pairs: PhotoFilePair[] = [];

    if (!photos || photos.length === 0) {
        setPhotosLoadingState(100);
        return pairs;
    }

    for (let i = 0; i < photos.length; i++) {
        const photo = photos[i];
        const res = await axios.get(configInstance.urls.storage + photo.path, {
            responseType: "blob",
        });
        const file = new File([res.data], photo.filename);
        pairs.push({
            file,
            photo,
            isVisibleOnBgButtons: photo.isVisibleOnBgButtons,
            isVisibleOnMain: photo.isVisibleOnMain,
            isMain: photo.isMain,
            title: photo.title ?? " ",
            displayFormat: photo.displayFormat ?? DisplayFormat.XLARGE,
            thumbnailUrl: await resizeImageToSpecificWidth(file, 50),
        });
        setPhotosLoadingState(Math.ceil((i / photos.length) * 100));
    }

    return pairs;
}

export const WorkPhotoListEntry: React.FC<{
    photoFilePairIdx: number;
    photoFilePair: PhotoFilePair;
    setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
    editFormDataPhotoFilePairs: PhotoFilePair[];
    setEditFormDataPhotoFilePairs: React.Dispatch<React.SetStateAction<PhotoFilePair[]>>;
    setPhotoPreviewBackdrop: React.Dispatch<React.SetStateAction<PhotoPreviewBackdropState>>;
    setEditWorkFormData: React.Dispatch<React.SetStateAction<Partial<OrEmptyStrings<EditWorkInput>>>>;
    editWorkFormData: Partial<OrEmptyStrings<EditWorkInput>>;
}> = React.memo(props => {
    return (
        <Grid item key={props.photoFilePairIdx} xs={12}>
            <Paper
                elevation={1}
                sx={{
                    backgroundColor: adminTheme.palette.grey[50],
                }}
            >
                <Grid container sx={{ alignItems: "center" }}>
                    <Grid
                        item
                        onClick={() => {
                            props.setPhotoPreviewBackdrop({
                                visible: true,
                                src: URL.createObjectURL(props.photoFilePair.file),
                            });
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                width: "50px",
                                height: "50px",
                                ml: 0.5,
                                mt: 0.5,
                                mb: 0,
                                mr: 1,
                                borderRadius: "4px",
                            }}
                            loading="lazy"
                            src={props.photoFilePair.thumbnailUrl}
                            alt="thumbnail"
                        />
                    </Grid>
                    <Grid item sx={{ flexGrow: 2 }}>
                        <Grid container>
                            <Grid item sx={{ width: "100%", maxWidth: "400px", mr: 4 }}>
                                <TextValidator
                                    fullWidth
                                    name="photoTitle"
                                    label="Title"
                                    variant="outlined"
                                    size="small"
                                    onChange={event => {
                                        const newPair: PhotoFilePair = {
                                            ...props.photoFilePair,
                                            title: (event.target as any).value,
                                        };

                                        const oldPairIdx = props.editFormDataPhotoFilePairs.indexOf(props.photoFilePair);

                                        const newPairs = props.editFormDataPhotoFilePairs.map((v, i) => {
                                            if (i === oldPairIdx) {
                                                return newPair;
                                            }
                                            return v;
                                        });

                                        props.setHasUnsavedChanges(true);
                                        props.setEditFormDataPhotoFilePairs(newPairs);
                                    }}
                                    value={props.photoFilePair.title}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid item sx={{ mr: 1 }}>
                                {props.photoFilePair.isMain ? <Chip color="primary" label="MAIN" size="small" /> : null}
                            </Grid>
                            <Grid item sx={{ mr: 1 }}>
                                {props.photoFilePair.photo ? null : <Chip color="warning" label="NEW" size="small" />}
                            </Grid>
                            {props.photoFilePair.isMain ? (
                                <Grid item sx={{ display: "flex", alignItems: "center", mx: 0 }}>
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel>Main format</InputLabel>
                                        <Select
                                            label="Main format"
                                            value={props.editWorkFormData.mainPhotoDisplayFormat}
                                            // onChange={props.onEditWorkFormFieldUpdate}
                                            onChange={(event: SelectChangeEvent) => {
                                                props.setHasUnsavedChanges(true);

                                                props.setEditWorkFormData({
                                                    ...props.editWorkFormData,
                                                    mainPhotoDisplayFormat: event.target.value as DisplayFormat,
                                                });
                                            }}
                                        >
                                            <MenuItem value={DisplayFormat.XLARGE}>X-large</MenuItem>
                                            <MenuItem value={DisplayFormat.LARGE}>Large</MenuItem>
                                            <MenuItem value={DisplayFormat.MEDIUM}>Medium</MenuItem>
                                            <MenuItem value={DisplayFormat.SMALL}>Small</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : null}
                            <Grid item sx={{ display: "flex", alignItems: "center", mx: 3 }}>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel>Format</InputLabel>
                                    <Select
                                        label="Format"
                                        value={props.photoFilePair.displayFormat}
                                        onChange={(event: SelectChangeEvent) => {
                                            const newPair: PhotoFilePair = {
                                                ...props.photoFilePair,
                                                displayFormat: event.target.value as DisplayFormat,
                                            };

                                            const oldPairIdx = props.editFormDataPhotoFilePairs.indexOf(props.photoFilePair);

                                            const newPairs = props.editFormDataPhotoFilePairs.map((v, i) => {
                                                if (i === oldPairIdx) {
                                                    return newPair;
                                                }
                                                return v;
                                            });

                                            props.setHasUnsavedChanges(true);
                                            props.setEditFormDataPhotoFilePairs(newPairs);
                                        }}
                                    >
                                        <MenuItem value={DisplayFormat.XLARGE}>X-large</MenuItem>
                                        <MenuItem value={DisplayFormat.LARGE}>Large</MenuItem>
                                        <MenuItem value={DisplayFormat.MEDIUM}>Medium</MenuItem>
                                        <MenuItem value={DisplayFormat.SMALL}>Small</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ display: "flex", alignItems: "center", mx: 3 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name={"showInMenu"}
                                            checked={props.photoFilePair.isVisibleOnBgButtons}
                                            onChange={() => {
                                                const newPair: PhotoFilePair = {
                                                    ...props.photoFilePair,
                                                    isVisibleOnBgButtons: !props.photoFilePair.isVisibleOnBgButtons,
                                                };

                                                const oldPairIdx = props.editFormDataPhotoFilePairs.indexOf(props.photoFilePair);

                                                const newPairs = props.editFormDataPhotoFilePairs.map((v, i) => {
                                                    if (i === oldPairIdx) {
                                                        return newPair;
                                                    }
                                                    return v;
                                                });

                                                props.setHasUnsavedChanges(true);
                                                props.setEditFormDataPhotoFilePairs(newPairs);
                                            }}
                                        />
                                    }
                                    label="In menu"
                                />
                            </Grid>
                            <Grid item sx={{ display: "flex", alignItems: "center", mx: 3 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name={"showInMain"}
                                            checked={props.photoFilePair.isVisibleOnMain}
                                            onChange={() => {
                                                const newPair: PhotoFilePair = {
                                                    ...props.photoFilePair,
                                                    isVisibleOnMain: !props.photoFilePair.isVisibleOnMain,
                                                };

                                                const oldPairIdx = props.editFormDataPhotoFilePairs.indexOf(props.photoFilePair);

                                                const newPairs = props.editFormDataPhotoFilePairs.map((v, i) => {
                                                    if (i === oldPairIdx) {
                                                        return newPair;
                                                    }
                                                    return v;
                                                });

                                                props.setHasUnsavedChanges(true);
                                                props.setEditFormDataPhotoFilePairs(newPairs);
                                            }}
                                        />
                                    }
                                    label="In landing"
                                />
                            </Grid>
                            <Grid item>
                                <Tooltip title="Move up">
                                    <span>
                                        <IconButton
                                            onClick={() => {
                                                props.setHasUnsavedChanges(true);
                                                props.setEditFormDataPhotoFilePairs(
                                                    moveArrayEl(
                                                        props.editFormDataPhotoFilePairs,
                                                        props.photoFilePairIdx,
                                                        props.photoFilePairIdx - 1,
                                                    ),
                                                );
                                            }}
                                            disabled={props.photoFilePairIdx === 0}
                                        >
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Move down">
                                    <span>
                                        <IconButton
                                            onClick={() => {
                                                props.setHasUnsavedChanges(true);
                                                props.setEditFormDataPhotoFilePairs(
                                                    moveArrayEl(
                                                        props.editFormDataPhotoFilePairs,
                                                        props.photoFilePairIdx,
                                                        props.photoFilePairIdx + 1,
                                                    ),
                                                );
                                            }}
                                            disabled={props.photoFilePairIdx === props.editFormDataPhotoFilePairs.length - 1}
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Mark as main">
                                    <span>
                                        <IconButton
                                            onClick={() => {
                                                props.setHasUnsavedChanges(true);
                                                props.setEditFormDataPhotoFilePairs(
                                                    props.editFormDataPhotoFilePairs.map((v, i) => ({
                                                        ...v,
                                                        isMain: i === props.photoFilePairIdx,
                                                    })),
                                                );
                                            }}
                                            disabled={props.photoFilePair.isMain}
                                        >
                                            <StarIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => {
                                            props.setHasUnsavedChanges(true);
                                            props.setEditFormDataPhotoFilePairs(
                                                props.editFormDataPhotoFilePairs.filter((_, i) => i !== props.photoFilePairIdx),
                                            );
                                        }}
                                        color="error"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
});

export const WorkEdit: React.FC<{}> = () => {
    let { id } = useParams();
    let navigate: NavigateFunction = useNavigate();

    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);
    const { enqueueSnackbar } = useSnackbar();

    const [tinyEditorLoading, setTinyEditorLoading] = React.useState<boolean>(true);
    const [tinyEditorIgnoreUnsavedChanges, setTinyEditorIgnoreUnsavedChanges] = React.useState<{
        descriptionLeft: boolean;
        descriptionRight: boolean;
    }>({ descriptionLeft: true, descriptionRight: true });
    const [parsedParamId, setParsedParamId] = React.useState<string>();
    const [work, setWork] = React.useState<Work>();
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);
    const [saveBtnLoadingState, setSaveBtnLoadingState] = React.useState<boolean | number>(false);
    const [editWorkFormData, setEditWorkFormData] = React.useState<Partial<OrEmptyStrings<EditWorkInput>>>(defaultEditWorkFormData);
    const [editFormDataPhotoFilePairs, setEditFormDataPhotoFilePairs] = React.useState<PhotoFilePair[]>([]);
    const [photosLoadingState, setPhotosLoadingState] = React.useState<number>(0);
    const [photoPreviewBackdrop, setPhotoPreviewBackdrop] = React.useState<PhotoPreviewBackdropState>({
        src: "",
        visible: false,
    });

    const uploadPhotos = async (): Promise<PhotosData> => {
        const photosData: PhotosData = {};

        for (let photoFilePairIdx = 0; photoFilePairIdx < editFormDataPhotoFilePairs.length; photoFilePairIdx++) {
            setSaveBtnLoadingState(Math.ceil((photoFilePairIdx / editFormDataPhotoFilePairs.length) * 100));

            const photoFilePair = editFormDataPhotoFilePairs[photoFilePairIdx];
            let addedPhoto: Photo;
            if (photoFilePair.photo) {
                addedPhoto = photoFilePair.photo;
            } else {
                addedPhoto = await WorkService.workControllerWorkPhotoAdd({
                    photo: photoFilePair.file,
                });
            }

            photosData[addedPhoto.id] = {
                index: photoFilePairIdx,
                isMain: photoFilePair.isMain,
                title: photoFilePair.title,
                displayFormat: photoFilePair.displayFormat,
                isVisibleOnBgButtons: photoFilePair.isVisibleOnBgButtons,
                isVisibleOnMain: photoFilePair.isVisibleOnMain,
            };
        }
        return photosData;
    };

    const onEditWorkFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setSaveBtnLoadingState(true);

        uploadPhotos()
            .then(photosData => {
                WorkService.workControllerWorkEdit({
                    id: parsedParamId!,
                    descriptionLeft: editWorkFormData.descriptionLeft as string,
                    descriptionRight: editWorkFormData.descriptionRight as string,
                    mainPhotoDisplayFormat: editWorkFormData.mainPhotoDisplayFormat as DisplayFormat,
                    isActive: editWorkFormData.isActive as boolean,
                    url: editWorkFormData.url as string,
                    title: editWorkFormData.title as string,
                    photosData,
                });
            })
            .then(() => {
                setHasUnsavedChanges(false);
                navigate(0); // reload
                enqueueSnackbar(<Typography variant="subtitle1">Work saved</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err?.message ? err.message : JSON.stringify(err)}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            })
            .finally(() => {
                setTimeout(() => setSaveBtnLoadingState(false), 2000);
            });
    };

    const onEditWorkFormSubmitError = (e: any) => {
        console.error(e);
        enqueueSnackbar(<Typography variant="subtitle1">Some error occured, see the browser console.</Typography>, {
            variant: "error",
            autoHideDuration: 2000,
        });
    };

    const onEditWorkFormFieldUpdate = (event: any) => {
        if (!event.tinyEditorIgnoreUnsavedChanges) {
            setHasUnsavedChanges(true);
        }

        setEditWorkFormData({
            ...editWorkFormData,
            [event.target.name as keyof EditWorkInput]: event.target.value,
        });
    };

    const onEditWorkFormSwitchUpdate = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setHasUnsavedChanges(true);
        setEditWorkFormData({
            ...editWorkFormData,
            [event.target.name as keyof EditWorkInput]: checked,
        });
    };

    const onEditWorkFormFileInputUpdate = async (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (event) {
            setHasUnsavedChanges(true);

            let filesToAdd: PhotoFilePair[] = [];
            if (event.target.files) {
                const filesArr = Array.from(event.target.files);
                for (const file of filesArr) {
                    const thumbnailUrl = await resizeImageToSpecificWidth(file, 50);
                    filesToAdd.push({
                        file,
                        title: " ",
                        isVisibleOnBgButtons: false,
                        isVisibleOnMain: false,
                        isMain: false,
                        displayFormat: DisplayFormat.XLARGE,
                        thumbnailUrl,
                    });
                }
            }

            if (filesToAdd.length > 0) {
                setEditFormDataPhotoFilePairs([...editFormDataPhotoFilePairs, ...filesToAdd]);
            }
        }
    };

    useEffect(() => {
        setTitle(`admin | work edit`);
        setNavBarKey("work");

        if (id) {
            setParsedParamId(id);
        } else {
            enqueueSnackbar(<Typography variant="subtitle1">Invalid parameter {id}.</Typography>, {
                variant: "error",
                autoHideDuration: 4000,
            });
            return navigate("/admin/works");
        }

        WorkService.workControllerWorkDetails(id!)
            .then(async workWithNext => {
                setWork(workWithNext.work);

                setEditWorkFormData({
                    descriptionLeft: workWithNext.work.descriptionLeft ?? "",
                    descriptionRight: workWithNext.work.descriptionRight ?? "",
                    isActive: workWithNext.work.isActive,
                    url: workWithNext.work.url ?? "",
                    title: workWithNext.work.title,
                    index: workWithNext.work.index,
                    mainPhotoDisplayFormat: (workWithNext.work.mainPhotoDisplayFormat as DisplayFormat) ?? "",
                    // photosData: photosData,
                    // files and some other file related fields are set in another useEffect
                });

                const photoFilePairs = await photoEntitiesToPhotoFilePairs(workWithNext.work.photos, setPhotosLoadingState);
                setEditFormDataPhotoFilePairs(photoFilePairs);
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if (work?.photos) {
    //         photoEntitiesToPhotoFilePairs(work.photos).then(pairs => {
    //             setEditWorkFormData({ ...editWorkFormData });
    //             setEditFormDataPhotoFilePairs(pairs);
    //             setPhotosLoading(false);
    //         });
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [work]);

    return (
        <>
            <PhotoPreviewBackdrop state={photoPreviewBackdrop} setState={setPhotoPreviewBackdrop} />
            {hasUnsavedChanges ? (
                <Snackbar open={true} anchorOrigin={{ vertical: "top", horizontal: "right" }} sx={{ mr: 22, mt: -2 }}>
                    <Alert severity="warning" variant="filled">
                        Unsaved changes
                    </Alert>
                </Snackbar>
            ) : null}

            <Grid container sx={{ justifyContent: "center" }}>
                {work ? (
                    <Grow in>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <ValidatorForm
                                    onSubmit={onEditWorkFormSubmit}
                                    onError={onEditWorkFormSubmitError}
                                    sx={{ display: "flex", flexDirection: "column", gap: 22 }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Typography variant="h6">General</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                name={"title" as keyof EditWorkInput}
                                                label="Title"
                                                variant="outlined"
                                                onChange={onEditWorkFormFieldUpdate}
                                                value={editWorkFormData.title}
                                                validators={["required", "maxStringLength:255"]}
                                                errorMessages={["Field is required", "Max length is 255 characters"]}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                name={"url" as keyof EditWorkInput}
                                                label="Url"
                                                variant="outlined"
                                                onChange={onEditWorkFormFieldUpdate}
                                                value={editWorkFormData.url}
                                                validators={["required", "matchRegexp:^[a-zA-Z0-9_-]*$"]}
                                                errorMessages={["Field is required", "Field has invalid characters"]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name={"isActive" as keyof EditWorkInput}
                                                        checked={!!editWorkFormData.isActive}
                                                        onChange={onEditWorkFormSwitchUpdate}
                                                    />
                                                }
                                                label="Mark as active"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Typography variant="h6">Description left</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TinyEditor
                                                isLoading={tinyEditorLoading}
                                                setIsLoading={setTinyEditorLoading}
                                                onEditorChange={value => {
                                                    onEditWorkFormFieldUpdate({
                                                        target: { name: "descriptionLeft" as keyof EditWorkInput, value },
                                                        tinyEditorIgnoreUnsavedChanges: tinyEditorIgnoreUnsavedChanges.descriptionLeft,
                                                    });
                                                    setTinyEditorIgnoreUnsavedChanges({
                                                        ...tinyEditorIgnoreUnsavedChanges,
                                                        descriptionLeft: false,
                                                    });
                                                }}
                                                value={editWorkFormData.descriptionLeft}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Typography variant="h6">Description right</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TinyEditor
                                                isLoading={tinyEditorLoading}
                                                setIsLoading={setTinyEditorLoading}
                                                onEditorChange={value => {
                                                    onEditWorkFormFieldUpdate({
                                                        target: { name: "descriptionRight" as keyof EditWorkInput, value },
                                                        tinyEditorIgnoreUnsavedChanges: tinyEditorIgnoreUnsavedChanges.descriptionRight,
                                                    });
                                                    setTinyEditorIgnoreUnsavedChanges({
                                                        ...tinyEditorIgnoreUnsavedChanges,
                                                        descriptionRight: false,
                                                    });
                                                }}
                                                value={editWorkFormData.descriptionRight}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>
                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6">Photos</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={8}>
                                                    <Typography variant="subtitle1">
                                                        Field "Title" is optional. Switch "In menu" is for photos that will be used in the
                                                        MENU (behind the social media URL). Switch "In landing" is for photos that will be
                                                        shown in the main page slider
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ display: "flex", justifyContent: "end", alignContent: "end" }}>
                                                    <Button variant="contained" component="label">
                                                        Upload photos
                                                        <input
                                                            type="file"
                                                            hidden
                                                            multiple
                                                            accept="image/png, image/gif, image/jpeg"
                                                            onChange={onEditWorkFormFileInputUpdate}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                {editFormDataPhotoFilePairs.length > 0 ? (
                                                    editFormDataPhotoFilePairs.map((photoFilePair, photoFilePairIdx) => (
                                                        <WorkPhotoListEntry
                                                            key={photoFilePairIdx}
                                                            photoFilePairIdx={photoFilePairIdx}
                                                            photoFilePair={photoFilePair}
                                                            setHasUnsavedChanges={setHasUnsavedChanges}
                                                            editFormDataPhotoFilePairs={editFormDataPhotoFilePairs}
                                                            setEditFormDataPhotoFilePairs={setEditFormDataPhotoFilePairs}
                                                            setPhotoPreviewBackdrop={setPhotoPreviewBackdrop}
                                                            setEditWorkFormData={setEditWorkFormData}
                                                            editWorkFormData={editWorkFormData}
                                                        />
                                                    ))
                                                ) : photosLoadingState !== 100 ? (
                                                    <Grid item xs={12}>
                                                        <LinearProgress variant="determinate" value={photosLoadingState} />
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1">No photos uploaded yet.</Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={<SaveIcon />}
                                                    color="success"
                                                    disabled={saveBtnLoadingState !== false}
                                                >
                                                    {typeof saveBtnLoadingState == "number" ? `${saveBtnLoadingState}%` : "Save"}
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Paper>
                        </Grid>
                    </Grow>
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        </>
    );
};
