import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    Grow,
    Paper,
    Snackbar,
    Switch,
    Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { EditPublicationInput, Photo, Publication, PublicationService } from "../../../client/generated";
import { configInstance } from "../../../config";
import { CurrentPageContext } from "../../../context/CurrentPageContext";
import { OrEmptyStrings } from "../../../types";
import { adminTheme } from "../../App";
import { PhotoPreviewBackdrop, PhotoPreviewBackdropState } from "../PhotoPreviewBackdrop";
import { TinyEditor } from "../TinyEditor";

const defaultEditPublicationFormData: Partial<OrEmptyStrings<EditPublicationInput>> = {
    isActive: true,
    url: "",
    title: "",
    titleFancy: "",
    photo: undefined,
};

async function photoEntityToPhotoFile(photo: Photo): Promise<File> {
    const res = await axios.get(configInstance.urls.storage + photo.path, {
        responseType: "blob",
    });
    const file = new File([res.data], photo.filename);
    return file;
}

type Props = {};

export const PublicationEdit: React.FC<Props> = () => {
    let { id } = useParams();
    let navigate: NavigateFunction = useNavigate();

    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);
    const { enqueueSnackbar } = useSnackbar();

    const [tinyEditorLoading, setTinyEditorLoading] = React.useState<boolean>(true);
    const [tinyEditorIgnoreUnsavedChanges, setTinyEditorIgnoreUnsavedChanges] = React.useState<{
        titleFancy: boolean;
    }>({ titleFancy: true });
    const [parsedParamId, setParsedParamId] = React.useState<string>();
    const [publication, setPublication] = React.useState<Publication>();
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);
    const [isSaveBtnLoading, setIsSaveBtnLoading] = React.useState<boolean>(false);
    const [editPublicationFormData, setEditPublicationFormData] =
        React.useState<Partial<OrEmptyStrings<EditPublicationInput>>>(defaultEditPublicationFormData);
    const [photosLoading, setPhotosLoading] = React.useState<boolean>(true);
    const [photoPreviewBackdrop, setPhotoPreviewBackdrop] = React.useState<PhotoPreviewBackdropState>({
        src: "",
        visible: false,
    });

    const onEditPublicationFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setIsSaveBtnLoading(true);
        PublicationService.publicationControllerPublicationEdit({
            id: parsedParamId!,
            isActive: editPublicationFormData.isActive as boolean,
            url: editPublicationFormData.url as string,
            title: editPublicationFormData.title as string,
            titleFancy: editPublicationFormData.titleFancy as string,
            photo: editPublicationFormData.photo as File,
        })
            .then(() => {
                setHasUnsavedChanges(false);
                navigate(0); // reload
                enqueueSnackbar(<Typography variant="subtitle1">Publication saved</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            })
            .finally(() => {
                setTimeout(() => setIsSaveBtnLoading(false), 2000);
            });
    };

    const onEditPublicationFormSubmitError = (e: any) => {
        console.error(e);
        enqueueSnackbar(<Typography variant="subtitle1">Some error occured, see the browser console.</Typography>, {
            variant: "error",
            autoHideDuration: 2000,
        });
    };

    const onEditPublicationFormFieldUpdate = (event: any) => {
        if (!event.tinyEditorIgnoreUnsavedChanges) {
            setHasUnsavedChanges(true);
        }

        setEditPublicationFormData({
            ...editPublicationFormData,
            [event.target.name as keyof EditPublicationInput]: event.target.value,
        });
    };

    const onEditPublicationFormSwitchUpdate = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setHasUnsavedChanges(true);
        setEditPublicationFormData({
            ...editPublicationFormData,
            [event.target.name as keyof EditPublicationInput]: checked,
        });
    };

    const onEditPublicationFormFileInputUpdate = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (event) {
            setHasUnsavedChanges(true);

            const photoFile = event.target.files ? Array.from(event.target.files)[0] : null;
            if (photoFile) {
                setEditPublicationFormData({ ...editPublicationFormData, photo: photoFile });
            }
        }
    };

    useEffect(() => {
        setTitle(`admin | publication edit`);
        setNavBarKey("publication");

        if (id) {
            setParsedParamId(id);
        } else {
            enqueueSnackbar(<Typography variant="subtitle1">Invalid parameter {id}.</Typography>, {
                variant: "error",
                autoHideDuration: 4000,
            });
            return navigate("/admin/publications");
        }

        PublicationService.publicationControllerPublicationDetails(id!)
            .then(publicationWithNext => {
                setPublication(publicationWithNext.publication);
                setEditPublicationFormData({
                    isActive: publicationWithNext.publication.isActive,
                    url: publicationWithNext.publication.url ?? "",
                    title: publicationWithNext.publication.title ?? "",
                    titleFancy: publicationWithNext.publication.titleFancy ?? "",
                    // files and some other file related fields are set in another useEffect
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (publication?.photo) {
            photoEntityToPhotoFile(publication.photo).then(photoFile => {
                setEditPublicationFormData({ ...editPublicationFormData, photo: photoFile });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publication]);

    return (
        <>
            <PhotoPreviewBackdrop state={photoPreviewBackdrop} setState={setPhotoPreviewBackdrop} />
            {hasUnsavedChanges ? (
                <Snackbar open={true} anchorOrigin={{ vertical: "top", horizontal: "right" }} sx={{ mr: 22, mt: -2 }}>
                    <Alert severity="warning" variant="filled">
                        Unsaved changes
                    </Alert>
                </Snackbar>
            ) : null}

            <Grid container sx={{ justifyContent: "center" }}>
                {publication ? (
                    <Grow in>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <ValidatorForm
                                    onSubmit={onEditPublicationFormSubmit}
                                    onError={onEditPublicationFormSubmitError}
                                    sx={{ display: "flex", flexDirection: "column", gap: 22 }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Typography variant="h6">General</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                name={"title" as keyof EditPublicationInput}
                                                label="Title NOT visible on the public site"
                                                variant="outlined"
                                                onChange={onEditPublicationFormFieldUpdate}
                                                value={editPublicationFormData.title}
                                                validators={["required", "maxStringLength:255"]}
                                                errorMessages={["Field is required", "Max length is 255 characters"]}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Title visible on the public site ("fancy" one)</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TinyEditor
                                                isLoading={tinyEditorLoading}
                                                setIsLoading={setTinyEditorLoading}
                                                onEditorChange={value => {
                                                    onEditPublicationFormFieldUpdate({
                                                        target: { name: "titleFancy" as keyof EditPublicationInput, value },
                                                        tinyEditorIgnoreUnsavedChanges: tinyEditorIgnoreUnsavedChanges.titleFancy,
                                                    });
                                                    setTinyEditorIgnoreUnsavedChanges({
                                                        ...tinyEditorIgnoreUnsavedChanges,
                                                        titleFancy: false,
                                                    });
                                                }}
                                                value={editPublicationFormData.titleFancy}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                name={"url" as keyof EditPublicationInput}
                                                label="Url"
                                                variant="outlined"
                                                onChange={onEditPublicationFormFieldUpdate}
                                                value={editPublicationFormData.url}
                                                validators={["required"]}
                                                errorMessages={["Field is required"]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name={"isActive" as keyof EditPublicationInput}
                                                        checked={!!editPublicationFormData.isActive}
                                                        onChange={onEditPublicationFormSwitchUpdate}
                                                    />
                                                }
                                                label="Mark as active"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Photo</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Paper
                                                elevation={1}
                                                sx={{
                                                    backgroundColor: adminTheme.palette.grey[50],
                                                }}
                                            >
                                                <Grid container sx={{ alignItems: "center" }}>
                                                    {editPublicationFormData.photo ? (
                                                        <Grid
                                                            item
                                                            onClick={() =>
                                                                setPhotoPreviewBackdrop({
                                                                    visible: true,
                                                                    src: URL.createObjectURL(editPublicationFormData.photo as Blob),
                                                                })
                                                            }
                                                        >
                                                            <Avatar
                                                                className="hoverPointer"
                                                                sx={{ borderRadius: 0, m: 1 }}
                                                                alt=" "
                                                                src={URL.createObjectURL(editPublicationFormData.photo as Blob)}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        <Grid item sx={{ m: 1, ml: 2 }}>
                                                            <Typography variant="body1">No photo uploaded yet.</Typography>{" "}
                                                        </Grid>
                                                    )}

                                                    <Grid item sx={{ flexGrow: 2 }}>
                                                        <Grid container>
                                                            <Grid item sx={{ width: "100%" }}>
                                                                <Typography variant="body1">
                                                                    {/* {JSON.stringify(photoFilePair)} */}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sx={{ m: 1, mr: 2 }}>
                                                        <Button variant="contained" component="label">
                                                            Upload photo
                                                            <input
                                                                type="file"
                                                                hidden
                                                                accept="image/png, image/gif, image/jpeg"
                                                                onChange={onEditPublicationFormFileInputUpdate}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={<SaveIcon />}
                                                    color="success"
                                                    disabled={isSaveBtnLoading}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Paper>
                        </Grid>
                    </Grow>
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        </>
    );
};
