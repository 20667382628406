import { Grid, Typography } from "@mui/material";
import htmlReactParser from "html-react-parser";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { MetaContext } from "../../context/MetaContext";
import { publicTheme } from "../App";

type Props = {};

export const SiteDisabled: React.FC<Props> = () => {
    const { setTitle } = React.useContext(CurrentPageContext);
    const { metaWithPhotos } = React.useContext(MetaContext);

    useEffect(() => {
        setTitle("contact");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Grid
                container
                sx={{
                    ml: "30vw",
                    [publicTheme.breakpoints.between("sm", "lg")]: { ml: "126px" },
                    [publicTheme.breakpoints.down("sm")]: { ml: "8px" },
                }}
            >
                <Grid
                    item
                    sx={{
                        zIndex: 100,
                        width: `calc(100% - 30vw)`,
                        marginTop: `15vh`,
                        [publicTheme.breakpoints.down("sm")]: {
                            "> .MuiTypography-root": { fontSize: "34px!important;" },
                            width: "100%",
                            marginTop: "130px",
                        },
                    }}
                >
                    {metaWithPhotos?.meta.contactText ? htmlReactParser(metaWithPhotos.meta.contactText) : null}
                    <br />
                    <br />
                    <br />
                    <Typography variant="h2">LAUNCHING SOON</Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        position: "absolute",
                        bottom: publicTheme.spacing(2),
                        left: publicTheme.spacing(4),
                        wordWrap: "break-word",
                        [publicTheme.breakpoints.down("sm")]: {
                            left: "8px",
                            bottom: "8px",
                        },
                    }}
                >
                    <Link to="/imprint">
                        <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                            IMPRINT & DATA PROTECTION
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
