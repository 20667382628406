/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPublicationInput } from '../models/AddPublicationInput';
import type { DeleteByUuidInput } from '../models/DeleteByUuidInput';
import type { EditPublicationInput } from '../models/EditPublicationInput';
import type { EditPublicationsIndex } from '../models/EditPublicationsIndex';
import type { PaginatedPublication } from '../models/PaginatedPublication';
import type { Publication } from '../models/Publication';
import type { PublicationWithNext } from '../models/PublicationWithNext';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicationService {

    /**
     * Get publication list
     * @param offset
     * @param limit
     * @param filter
     * @param sort
     * @returns PaginatedPublication
     * @throws ApiError
     */
    public static publicationControllerPublicationList(
        offset?: number,
        limit?: number,
        filter?: string,
        sort?: string,
    ): CancelablePromise<PaginatedPublication> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/publication',
            query: {
                'offset': offset,
                'limit': limit,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Add publication
     * @param requestBody
     * @returns Publication
     * @throws ApiError
     */
    public static publicationControllerPublicationAdd(
        requestBody: AddPublicationInput,
    ): CancelablePromise<Publication> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/publication',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Edit publication
     * @param formData
     * @returns void
     * @throws ApiError
     */
    public static publicationControllerPublicationEdit(
        formData: EditPublicationInput,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/publication',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Delete publication
     * @param requestBody
     * @returns Publication
     * @throws ApiError
     */
    public static publicationControllerPublicationRemove(
        requestBody: DeleteByUuidInput,
    ): CancelablePromise<Publication> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/publication',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Edit publications index
     * @param requestBody
     * @returns Publication
     * @throws ApiError
     */
    public static publicationControllerPublicationEditIndex(
        requestBody: EditPublicationsIndex,
    ): CancelablePromise<Publication> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/publication/index',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get publication details
     * @param idOrUrl
     * @returns PublicationWithNext
     * @throws ApiError
     */
    public static publicationControllerPublicationDetails(
        idOrUrl: string,
    ): CancelablePromise<PublicationWithNext> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/publication/{idOrUrl}',
            path: {
                'idOrUrl': idOrUrl,
            },
        });
    }

}
