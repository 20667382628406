import { Box, Fade, Grid } from "@mui/material";
import React from "react";
import { configInstance } from "../../config";
import useThrottledScroll from "../../hooks/useThrottledScroll";
import { publicTheme } from "../App";

type Props = {};

export const ScrollToTopButton: React.FC<Props> = props => {
    const [scrollTop, setScrollTop] = React.useState(0);

    const handleScroll = () => {
        setScrollTop(window.scrollY);
    };

    useThrottledScroll(handleScroll, 1000);

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <React.Fragment>
            <Fade in={scrollTop > 300} mountOnEnter>
                <Grid
                    container
                    sx={{
                        position: "fixed",
                        bottom: "16px",
                        left: "16px",
                        zIndex: 200,
                        [publicTheme.breakpoints.down("sm")]: {
                            left: "-8px",
                            bottom: "8px",
                        },
                    }}
                >
                    <Grid item onClick={handleScrollToTop} className="hover-pointer">
                        <Box
                            component="img"
                            src={configInstance.urls.frontend + "/images/scrollTop.png"}
                            alt={""}
                            sx={{
                                height: "50px",
                                width: "auto",
                                ml: 2,
                            }}
                        />
                    </Grid>
                </Grid>
            </Fade>
        </React.Fragment>
    );
};
