import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Avatar, Box, Button, CircularProgress, Divider, Drawer, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { formatDistance, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate } from "react-router-dom";
import { AddWorkInput, EditWorksIndex, Work, WorkService } from "../../../client/generated";
import { configInstance } from "../../../config";
import { CurrentPageContext } from "../../../context/CurrentPageContext";
import { getPathSm } from "../../../helpers/getPathSm";
import { OrEmptyStrings } from "../../../types";
import { adminTheme } from "../../App";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { PhotoPreviewBackdrop, PhotoPreviewBackdropState } from "../PhotoPreviewBackdrop";

type Props = {};

// todo remove
const defaultAddWorkFormData: Partial<OrEmptyStrings<AddWorkInput>> = {
    title: "",
};

export const WorkList: React.FC<Props> = () => {
    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [needsToFetch, setNeedsToFetch] = React.useState<boolean>(true);
    const [works, setWorks] = React.useState<Work[]>([]);
    const [workToDeactivate, setWorkToDeactivate] = React.useState<Work>();
    const [workToActivate, setWorkToActivate] = React.useState<Work>();
    const [workToDelete, setWorkToDelete] = React.useState<Work>();
    const [isAddWorkDrawerOpen, setAddWorkDrawerOpen] = React.useState<boolean | undefined>(undefined);
    const [addWorkFormData, setAddWorkFormData] = React.useState<Partial<OrEmptyStrings<AddWorkInput>>>(defaultAddWorkFormData);
    const [photoPreviewBackdrop, setPhotoPreviewBackdrop] = React.useState<PhotoPreviewBackdropState>({
        src: "",
        visible: false,
    });

    const onAddWorkFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        WorkService.workControllerWorkAdd({
            title: addWorkFormData.title as string,
        })
            .then(newWork => {
                setWorks([...works, newWork]);
                setAddWorkDrawerOpen(false);
                setAddWorkFormData(defaultAddWorkFormData);
                enqueueSnackbar(<Typography variant="subtitle1">Work added.</Typography>, {
                    variant: "success",
                    autoHideDuration: 6000,
                    action: snackbarId => {
                        return (
                            <Button
                                sx={{ color: "#ffffff" }}
                                size="small"
                                onClick={() => {
                                    navigate(`/admin/works/${newWork.id}`);
                                    closeSnackbar(snackbarId);
                                }}
                            >
                                Open
                            </Button>
                        );
                    },
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    const onFormSubmitError = (e: any) => {
        enqueueSnackbar(<Typography variant="subtitle1">{JSON.stringify(e)}</Typography>, {
            variant: "error",
            autoHideDuration: 2000,
        });
    };

    const onAddWorkFormFieldUpdate = (event: any) => {
        setAddWorkFormData({
            ...addWorkFormData,
            [event.target.name as keyof AddWorkInput]: event.target.value,
        });
    };

    const updateWorkVisibility = (id: string, isActive: boolean) => {
        WorkService.workControllerWorkEdit({ id, isActive })
            .then(() => {
                setWorks(
                    works.map(work => {
                        if (work.id === id) {
                            return {
                                ...work,
                                isActive,
                            };
                        }
                        return work;
                    }),
                );

                setWorkToDeactivate(undefined);
                setWorkToActivate(undefined);
                enqueueSnackbar(<Typography variant="subtitle1">{isActive ? "Work set to active." : "Work set to inactive."}</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    const updateWorksIndex = (input: EditWorksIndex) => {
        WorkService.workControllerWorkEditIndex(input)
            .then(() => {
                setNeedsToFetch(true);
                enqueueSnackbar(<Typography variant="subtitle1">Work index updated.</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    const deleteWork = (id: string) => {
        WorkService.workControllerWorkRemove({ id })
            .then(deletedWork => {
                setWorks(works.filter(work => work.id !== deletedWork.id));
                setWorkToDelete(undefined);
                enqueueSnackbar(<Typography variant="subtitle1">Work removed.</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    useEffect(() => {
        setTitle("admin | works");
        setNavBarKey("work");

        if (needsToFetch) {
            WorkService.workControllerWorkList(0, 100, undefined, JSON.stringify({ index: "ASC" }))
                .then(res => {
                    setWorks(res.result);
                })
                .catch(err => {
                    enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                        variant: "error",
                        autoHideDuration: 2000,
                    });
                    throw err;
                })
                .finally(() => setNeedsToFetch(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needsToFetch]);

    return (
        <>
            <ConfirmationDialog
                title="Are you sure you want to deactivate the work?"
                content={`This action will not completely remove the work from the internal database of works. In the future, it will be possible to reactivate the work.`}
                isOpen={!!workToDeactivate}
                onClose={() => setWorkToDeactivate(undefined)}
                onNoButtonClick={() => setWorkToDeactivate(undefined)}
                onYesButtonClick={() => updateWorkVisibility(workToDeactivate!.id, false)}
            />
            <ConfirmationDialog
                title="Are you sure you want to activate the work?"
                content="Activating the work will cause it to reappear on the home page."
                isOpen={!!workToActivate}
                onClose={() => setWorkToActivate(undefined)}
                onNoButtonClick={() => setWorkToActivate(undefined)}
                onYesButtonClick={() => updateWorkVisibility(workToActivate!.id, true)}
            />
            <ConfirmationDialog
                title="Are you sure you want to delete the work?"
                content="This action will remove the work from the internal database of works. This work cannot be restored. You will need to add it again."
                isOpen={!!workToDelete}
                onClose={() => setWorkToDelete(undefined)}
                onNoButtonClick={() => setWorkToDelete(undefined)}
                onYesButtonClick={() => deleteWork(workToDelete!.id)}
            />
            <PhotoPreviewBackdrop state={photoPreviewBackdrop} setState={setPhotoPreviewBackdrop} />
            <Drawer
                anchor="right"
                open={isAddWorkDrawerOpen}
                onClose={() => setAddWorkDrawerOpen(false)}
                PaperProps={{
                    sx: { width: { xs: "70%", lg: "50%" }, p: 2, backgroundColor: indigo[50] },
                }}
            >
                <Grid container sx={{ mb: 4 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Add work</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ my: 3 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatorForm
                            onSubmit={onAddWorkFormSubmit}
                            onError={onFormSubmitError}
                            sx={{ display: "flex", flexDirection: "column" }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    <Typography variant="h6">General</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        fullWidth
                                        name={"title" as keyof AddWorkInput}
                                        label="Title"
                                        variant="outlined"
                                        onChange={onAddWorkFormFieldUpdate}
                                        value={addWorkFormData.title}
                                        validators={["required", "maxStringLength:255"]}
                                        errorMessages={["Field is required", "Max length is 255 characters"]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 1 }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button type="submit" variant="contained" startIcon={<SaveIcon />} color="success" sx={{ mr: 1 }}>
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<ClearIcon />}
                                            color="error"
                                            onClick={() => setAddWorkDrawerOpen(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
            </Drawer>

            <Grid container spacing={2}>
                {needsToFetch ? (
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </Grid>
                ) : works.length === 0 ? (
                    <Grid item xs={12}>
                        <Paper
                            elevation={1}
                            sx={{
                                p: 4,
                                backgroundColor: adminTheme.palette.grey[200],
                            }}
                        >
                            <Typography variant="h6">No works found.</Typography>
                        </Paper>
                    </Grid>
                ) : (
                    works.map((work, i) => {
                        const mainPhotoUrl = work.mainPhoto?.path
                            ? configInstance.urls.storage + getPathSm(work.mainPhoto.path, 100)
                            : configInstance.urls.frontend + "/images/noPhoto.jpg";

                        return (
                            <Grid item key={`work_${i}`} xs={12}>
                                <Paper elevation={1} sx={{ opacity: work.isActive ? 1 : 0.2 }}>
                                    <Grid container sx={{ alignItems: "center" }}>
                                        <Grid item>
                                            <Link to={`/admin/works/${work.id}`}>
                                                <Avatar
                                                    className="hoverPointer"
                                                    sx={{ borderRadius: 0, m: 1 }}
                                                    alt=" "
                                                    src={mainPhotoUrl}
                                                />
                                            </Link>
                                        </Grid>
                                        <Grid item sx={{ flexGrow: 2 }}>
                                            <Link to={`/admin/works/${work.id}`}>
                                                <Grid container>
                                                    <Grid item sx={{ width: "100%" }}>
                                                        <Typography variant="body1">
                                                            {work.index} - {work.title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sx={{ width: "100%" }}>
                                                        <Typography variant="caption">{`Updated ${formatDistance(
                                                            parseISO(work.updatedAt),
                                                            new Date(),
                                                            {
                                                                addSuffix: true,
                                                            },
                                                        )}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                        </Grid>

                                        <Grid item>
                                            <Grid container sx={{ alignItems: "center" }}>
                                                <Grid item>
                                                    <Tooltip title="Move up">
                                                        <span>
                                                            <IconButton
                                                                onClick={() =>
                                                                    updateWorksIndex({
                                                                        workIndexMap: {
                                                                            [work.id]: work.index - 1,
                                                                            [works[i - 1].id]: works[i - 1].index + 1,
                                                                        },
                                                                    })
                                                                }
                                                                disabled={i === 0}
                                                            >
                                                                <ArrowUpwardIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Move down">
                                                        <span>
                                                            <IconButton
                                                                onClick={() =>
                                                                    updateWorksIndex({
                                                                        workIndexMap: {
                                                                            [work.id]: work.index + 1,
                                                                            [works[i + 1].id]: works[i + 1].index - 1,
                                                                        },
                                                                    })
                                                                }
                                                                disabled={i + 1 === works.length}
                                                            >
                                                                <ArrowDownwardIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    {work.isActive ? (
                                                        <Tooltip title="Deactivate">
                                                            <IconButton
                                                                onClick={() => setWorkToDeactivate(work)}
                                                                sx={{
                                                                    transition: adminTheme.transitions.create("opacity", {
                                                                        easing: adminTheme.transitions.easing.sharp,
                                                                        duration: adminTheme.transitions.duration.enteringScreen,
                                                                    }),
                                                                }}
                                                            >
                                                                <VisibilityOffIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Activate">
                                                            <IconButton
                                                                onClick={() => setWorkToActivate(work)}
                                                                sx={{
                                                                    transition: adminTheme.transitions.create("opacity", {
                                                                        easing: adminTheme.transitions.easing.sharp,
                                                                        duration: adminTheme.transitions.duration.enteringScreen,
                                                                    }),
                                                                }}
                                                            >
                                                                <VisibilityOnIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Delete">
                                                        <IconButton onClick={() => setWorkToDelete(work)} color="error">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        );
                    })
                )}

                <Button
                    type="submit"
                    variant="contained"
                    startIcon={<AddIcon />}
                    color="primary"
                    size="large"
                    onClick={() => setAddWorkDrawerOpen(true)}
                    sx={{
                        position: "fixed",
                        boxShadow: 2,
                        bottom: theme => theme.spacing(2),
                        right: theme => theme.spacing(2),
                    }}
                >
                    Add
                </Button>
            </Grid>
        </>
    );
};
