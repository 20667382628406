import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Avatar, Box, Button, CircularProgress, Divider, Drawer, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { formatDistance, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate } from "react-router-dom";
import { AddPublicationInput, EditPublicationsIndex, Publication, PublicationService } from "../../../client/generated";
import { configInstance } from "../../../config";
import { CurrentPageContext } from "../../../context/CurrentPageContext";
import { getPathSm } from "../../../helpers/getPathSm";
import { OrEmptyStrings } from "../../../types";
import { adminTheme } from "../../App";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { PhotoPreviewBackdrop, PhotoPreviewBackdropState } from "../PhotoPreviewBackdrop";

type Props = {};

// todo remove
const defaultAddPublicationFormData: Partial<OrEmptyStrings<AddPublicationInput>> = {
    title: "",
};

export const PublicationList: React.FC<Props> = () => {
    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [needsToFetch, setNeedsToFetch] = React.useState<boolean>(true);
    const [publications, setPublications] = React.useState<Publication[]>([]);
    const [publicationToDeactivate, setPublicationToDeactivate] = React.useState<Publication>();
    const [publicationToActivate, setPublicationToActivate] = React.useState<Publication>();
    const [publicationToDelete, setPublicationToDelete] = React.useState<Publication>();
    const [isAddPublicationDrawerOpen, setAddPublicationDrawerOpen] = React.useState<boolean | undefined>(undefined);
    const [addPublicationFormData, setAddPublicationFormData] =
        React.useState<Partial<OrEmptyStrings<AddPublicationInput>>>(defaultAddPublicationFormData);
    const [photoPreviewBackdrop, setPhotoPreviewBackdrop] = React.useState<PhotoPreviewBackdropState>({
        src: "",
        visible: false,
    });

    const onAddPublicationFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        PublicationService.publicationControllerPublicationAdd({
            title: addPublicationFormData.title as string,
        })
            .then(newPublication => {
                setPublications([...publications, newPublication]);
                setAddPublicationDrawerOpen(false);
                setAddPublicationFormData(defaultAddPublicationFormData);
                enqueueSnackbar(<Typography variant="subtitle1">Publication added.</Typography>, {
                    variant: "success",
                    autoHideDuration: 6000,
                    action: snackbarId => {
                        return (
                            <Button
                                sx={{ color: "#ffffff" }}
                                size="small"
                                onClick={() => {
                                    navigate(`/admin/publications/${newPublication.id}`);
                                    closeSnackbar(snackbarId);
                                }}
                            >
                                Open
                            </Button>
                        );
                    },
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    const onFormSubmitError = (e: any) => {
        enqueueSnackbar(<Typography variant="subtitle1">{JSON.stringify(e)}</Typography>, {
            variant: "error",
            autoHideDuration: 2000,
        });
    };

    const onAddPublicationFormFieldUpdate = (event: any) => {
        setAddPublicationFormData({
            ...addPublicationFormData,
            [event.target.name as keyof AddPublicationInput]: event.target.value,
        });
    };

    const updatePublicationVisibility = (id: string, isActive: boolean) => {
        PublicationService.publicationControllerPublicationEdit({ id, isActive })
            .then(() => {
                setPublications(
                    publications.map(publication => {
                        if (publication.id === id) {
                            return {
                                ...publication,
                                isActive,
                            };
                        }
                        return publication;
                    }),
                );

                setPublicationToDeactivate(undefined);
                setPublicationToActivate(undefined);
                enqueueSnackbar(
                    <Typography variant="subtitle1">{isActive ? "Publication set to active." : "Publication set to inactive."}</Typography>,
                    {
                        variant: "success",
                        autoHideDuration: 2000,
                    },
                );
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    const updatePublicationsIndex = (input: EditPublicationsIndex) => {
        PublicationService.publicationControllerPublicationEditIndex(input)
            .then(() => {
                setNeedsToFetch(true);
                enqueueSnackbar(<Typography variant="subtitle1">Publication index updated.</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    const deletePublication = (id: string) => {
        PublicationService.publicationControllerPublicationRemove({ id })
            .then(deletedPublication => {
                setPublications(publications.filter(publication => publication.id !== deletedPublication.id));
                setPublicationToDelete(undefined);
                enqueueSnackbar(<Typography variant="subtitle1">Publication removed.</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    useEffect(() => {
        setTitle("admin | publications");
        setNavBarKey("publication");

        if (needsToFetch) {
            PublicationService.publicationControllerPublicationList(0, 100, undefined, JSON.stringify({ index: "ASC" }))
                .then(res => {
                    setPublications(res.result);
                })
                .catch(err => {
                    enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                        variant: "error",
                        autoHideDuration: 2000,
                    });
                    throw err;
                })
                .finally(() => setNeedsToFetch(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needsToFetch]);

    return (
        <>
            <ConfirmationDialog
                title="Are you sure you want to deactivate the publication?"
                content={`This action will not completely remove the publication from the internal database of publications. In the future, it will be possible to reactivate the publication.`}
                isOpen={!!publicationToDeactivate}
                onClose={() => setPublicationToDeactivate(undefined)}
                onNoButtonClick={() => setPublicationToDeactivate(undefined)}
                onYesButtonClick={() => updatePublicationVisibility(publicationToDeactivate!.id, false)}
            />
            <ConfirmationDialog
                title="Are you sure you want to activate the publication?"
                content="Activating the publication will cause it to reappear on the home page."
                isOpen={!!publicationToActivate}
                onClose={() => setPublicationToActivate(undefined)}
                onNoButtonClick={() => setPublicationToActivate(undefined)}
                onYesButtonClick={() => updatePublicationVisibility(publicationToActivate!.id, true)}
            />
            <ConfirmationDialog
                title="Are you sure you want to delete the publication?"
                content="This action will remove the publication from the internal database of publications. This publication cannot be restored. You will need to add it again."
                isOpen={!!publicationToDelete}
                onClose={() => setPublicationToDelete(undefined)}
                onNoButtonClick={() => setPublicationToDelete(undefined)}
                onYesButtonClick={() => deletePublication(publicationToDelete!.id)}
            />
            <PhotoPreviewBackdrop state={photoPreviewBackdrop} setState={setPhotoPreviewBackdrop} />
            <Drawer
                anchor="right"
                open={isAddPublicationDrawerOpen}
                onClose={() => setAddPublicationDrawerOpen(false)}
                PaperProps={{
                    sx: { width: { xs: "70%", lg: "50%" }, p: 2, backgroundColor: indigo[50] },
                }}
            >
                <Grid container sx={{ mb: 4 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Add publication</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ my: 3 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatorForm
                            onSubmit={onAddPublicationFormSubmit}
                            onError={onFormSubmitError}
                            sx={{ display: "flex", flexDirection: "column" }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    <Typography variant="h6">General</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator
                                        fullWidth
                                        name={"title" as keyof AddPublicationInput}
                                        label="Title"
                                        variant="outlined"
                                        onChange={onAddPublicationFormFieldUpdate}
                                        value={addPublicationFormData.title}
                                        validators={["required", "maxStringLength:255"]}
                                        errorMessages={["Field is required", "Max length is 255 characters"]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 1 }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button type="submit" variant="contained" startIcon={<SaveIcon />} color="success" sx={{ mr: 1 }}>
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<ClearIcon />}
                                            color="error"
                                            onClick={() => setAddPublicationDrawerOpen(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
            </Drawer>

            <Grid container spacing={2}>
                {needsToFetch ? (
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                        <CircularProgress />
                    </Grid>
                ) : publications.length === 0 ? (
                    <Grid item xs={12}>
                        <Paper
                            elevation={1}
                            sx={{
                                p: 4,
                                backgroundColor: adminTheme.palette.grey[200],
                            }}
                        >
                            <Typography variant="h6">No publications found.</Typography>
                        </Paper>
                    </Grid>
                ) : (
                    publications.map((publication, i) => {
                        const mainPhotoUrl = publication.photo?.path
                            ? configInstance.urls.storage + getPathSm(publication.photo.path, 100)
                            : configInstance.urls.frontend + "/images/noPhoto.jpg";

                        return (
                            <Grid item key={`publication_${i}`} xs={12}>
                                <Paper elevation={1} sx={{ opacity: publication.isActive ? 1 : 0.2 }}>
                                    <Grid container sx={{ alignItems: "center" }}>
                                        <Grid item>
                                            <Link to={`/admin/publications/${publication.id}`}>
                                                <Avatar
                                                    className="hoverPointer"
                                                    sx={{ borderRadius: 0, m: 1 }}
                                                    alt=" "
                                                    src={mainPhotoUrl}
                                                />
                                            </Link>
                                        </Grid>
                                        <Grid item sx={{ flexGrow: 2 }}>
                                            <Link to={`/admin/publications/${publication.id}`}>
                                                <Grid container>
                                                    <Grid item sx={{ width: "100%" }}>
                                                        <Typography variant="body1">
                                                            {publication.index} - {publication.title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sx={{ width: "100%" }}>
                                                        <Typography variant="caption">{`Updated ${formatDistance(
                                                            parseISO(publication.updatedAt),
                                                            new Date(),
                                                            {
                                                                addSuffix: true,
                                                            },
                                                        )}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Link>
                                        </Grid>

                                        <Grid item>
                                            <Grid container sx={{ alignItems: "center" }}>
                                                <Grid item>
                                                    <Tooltip title="Move up">
                                                        <span>
                                                            <IconButton
                                                                onClick={() =>
                                                                    updatePublicationsIndex({
                                                                        publicationIndexMap: {
                                                                            [publication.id]: publication.index - 1,
                                                                            [publications[i - 1].id]: publications[i - 1].index + 1,
                                                                        },
                                                                    })
                                                                }
                                                                disabled={i === 0}
                                                            >
                                                                <ArrowUpwardIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Move down">
                                                        <span>
                                                            <IconButton
                                                                onClick={() =>
                                                                    updatePublicationsIndex({
                                                                        publicationIndexMap: {
                                                                            [publication.id]: publication.index + 1,
                                                                            [publications[i + 1].id]: publications[i + 1].index - 1,
                                                                        },
                                                                    })
                                                                }
                                                                disabled={i + 1 === publications.length}
                                                            >
                                                                <ArrowDownwardIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    {publication.isActive ? (
                                                        <Tooltip title="Deactivate">
                                                            <IconButton
                                                                onClick={() => setPublicationToDeactivate(publication)}
                                                                sx={{
                                                                    transition: adminTheme.transitions.create("opacity", {
                                                                        easing: adminTheme.transitions.easing.sharp,
                                                                        duration: adminTheme.transitions.duration.enteringScreen,
                                                                    }),
                                                                }}
                                                            >
                                                                <VisibilityOffIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Activate">
                                                            <IconButton
                                                                onClick={() => setPublicationToActivate(publication)}
                                                                sx={{
                                                                    transition: adminTheme.transitions.create("opacity", {
                                                                        easing: adminTheme.transitions.easing.sharp,
                                                                        duration: adminTheme.transitions.duration.enteringScreen,
                                                                    }),
                                                                }}
                                                            >
                                                                <VisibilityOnIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Delete">
                                                        <IconButton onClick={() => setPublicationToDelete(publication)} color="error">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        );
                    })
                )}

                <Button
                    type="submit"
                    variant="contained"
                    startIcon={<AddIcon />}
                    color="primary"
                    size="large"
                    onClick={() => setAddPublicationDrawerOpen(true)}
                    sx={{
                        position: "fixed",
                        boxShadow: 2,
                        bottom: theme => theme.spacing(2),
                        right: theme => theme.spacing(2),
                    }}
                >
                    Add
                </Button>
            </Grid>
        </>
    );
};
