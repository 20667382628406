/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddWorkInput } from '../models/AddWorkInput';
import type { AddWorkPhotoInput } from '../models/AddWorkPhotoInput';
import type { DeleteByUuidInput } from '../models/DeleteByUuidInput';
import type { EditWorkInput } from '../models/EditWorkInput';
import type { EditWorksIndex } from '../models/EditWorksIndex';
import type { PaginatedWork } from '../models/PaginatedWork';
import type { Photo } from '../models/Photo';
import type { Work } from '../models/Work';
import type { WorkWithNext } from '../models/WorkWithNext';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkService {

    /**
     * Get work list
     * @param offset
     * @param limit
     * @param filter
     * @param sort
     * @returns PaginatedWork
     * @throws ApiError
     */
    public static workControllerWorkList(
        offset?: number,
        limit?: number,
        filter?: string,
        sort?: string,
    ): CancelablePromise<PaginatedWork> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/work',
            query: {
                'offset': offset,
                'limit': limit,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Add work
     * @param requestBody
     * @returns Work
     * @throws ApiError
     */
    public static workControllerWorkAdd(
        requestBody: AddWorkInput,
    ): CancelablePromise<Work> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/work',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Edit work
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static workControllerWorkEdit(
        requestBody: EditWorkInput,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/work',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete work
     * @param requestBody
     * @returns Work
     * @throws ApiError
     */
    public static workControllerWorkRemove(
        requestBody: DeleteByUuidInput,
    ): CancelablePromise<Work> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/work',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add work photo
     * @param formData
     * @returns Photo
     * @throws ApiError
     */
    public static workControllerWorkPhotoAdd(
        formData: AddWorkPhotoInput,
    ): CancelablePromise<Photo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/work/photo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Edit works index
     * @param requestBody
     * @returns Work
     * @throws ApiError
     */
    public static workControllerWorkEditIndex(
        requestBody: EditWorksIndex,
    ): CancelablePromise<Work> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/work/index',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get work details
     * @param idOrUrl
     * @returns WorkWithNext
     * @throws ApiError
     */
    public static workControllerWorkDetails(
        idOrUrl: string,
    ): CancelablePromise<WorkWithNext> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/work/{idOrUrl}',
            path: {
                'idOrUrl': idOrUrl,
            },
        });
    }

}
