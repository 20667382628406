/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddExhibitionInput } from '../models/AddExhibitionInput';
import type { DeleteByUuidInput } from '../models/DeleteByUuidInput';
import type { EditExhibitionInput } from '../models/EditExhibitionInput';
import type { EditExhibitionsIndex } from '../models/EditExhibitionsIndex';
import type { Exhibition } from '../models/Exhibition';
import type { ExhibitionWithNext } from '../models/ExhibitionWithNext';
import type { PaginatedExhibition } from '../models/PaginatedExhibition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExhibitionService {

    /**
     * Get exhibition list
     * @param offset
     * @param limit
     * @param filter
     * @param sort
     * @returns PaginatedExhibition
     * @throws ApiError
     */
    public static exhibitionControllerExhibitionList(
        offset?: number,
        limit?: number,
        filter?: string,
        sort?: string,
    ): CancelablePromise<PaginatedExhibition> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/exhibition',
            query: {
                'offset': offset,
                'limit': limit,
                'filter': filter,
                'sort': sort,
            },
        });
    }

    /**
     * Add exhibition
     * @param requestBody
     * @returns Exhibition
     * @throws ApiError
     */
    public static exhibitionControllerExhibitionAdd(
        requestBody: AddExhibitionInput,
    ): CancelablePromise<Exhibition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/exhibition',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Edit exhibition
     * @param formData
     * @returns void
     * @throws ApiError
     */
    public static exhibitionControllerExhibitionEdit(
        formData: EditExhibitionInput,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/exhibition',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Delete exhibition
     * @param requestBody
     * @returns Exhibition
     * @throws ApiError
     */
    public static exhibitionControllerExhibitionRemove(
        requestBody: DeleteByUuidInput,
    ): CancelablePromise<Exhibition> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/exhibition',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Edit exhibitions index
     * @param requestBody
     * @returns Exhibition
     * @throws ApiError
     */
    public static exhibitionControllerExhibitionEditIndex(
        requestBody: EditExhibitionsIndex,
    ): CancelablePromise<Exhibition> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/exhibition/index',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get exhibition details
     * @param idOrUrl
     * @returns ExhibitionWithNext
     * @throws ApiError
     */
    public static exhibitionControllerExhibitionDetails(
        idOrUrl: string,
    ): CancelablePromise<ExhibitionWithNext> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/exhibition/{idOrUrl}',
            path: {
                'idOrUrl': idOrUrl,
            },
        });
    }

}
