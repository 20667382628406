import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Work } from "../../client/generated";
import { configInstance } from "../../config";
import { publicTheme } from "../App";

type Props = {
    titleEl: string | JSX.Element;
    backLink: string;
    position: "fixed" | "relative";
};

export function getWorkMainPhotoUrl(work: Work) {
    return work.mainPhoto?.path ? configInstance.urls.storage + work.mainPhoto.path : configInstance.urls.frontend + "/images/noPhoto.jpg";
}

export const TitleAndBackBtn: React.FC<Props> = props => {
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            sx={{
                mr: 32,
                mt: "7px",
                ml: 2,
                position: props.position,
                zIndex: 1050,
                [publicTheme.breakpoints.down("sm")]: {
                    mt: 0,
                    ml: 1,
                },
            }}
        >
            <Link to={props.backLink}>
                <Grid
                    item
                    sx={{
                        mr: 8,
                        mt: 0,
                        [publicTheme.breakpoints.down("lg")]: {
                            mr: 2,
                            mt: 0,
                        },
                        [publicTheme.breakpoints.down("sm")]: {
                            minWidth: "100vw",
                        },
                    }}
                >
                    <Box
                        component="img"
                        src={configInstance.urls.frontend + "/images/scrollTop.png"}
                        alt={""}
                        sx={{
                            rotate: "-90deg",
                            height: "50px",
                            width: "auto",
                            ml: 2,
                        }}
                    />
                </Grid>
            </Link>
            {props.titleEl}
        </Grid>
    );
};
