import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CurrentPageContext } from "../../context/CurrentPageContext";

type Props = {};

export const Exhibitions: React.FC<Props> = () => {
    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);

    useEffect(() => {
        setTitle("admin | exhibitions");
        setNavBarKey(undefined);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Grid container>
                <Box
                    sx={{
                        display: "flex",
                        flexFlow: "column",
                    }}
                >
                    <Paper
                        elevation={2}
                        sx={{
                            p: 4,
                            backgroundColor: "#fff",
                        }}
                    >
                        <Typography variant="h2">exhibitions</Typography>
                    </Paper>
                </Box>
            </Grid>
        </React.Fragment>
    );
};
