import { throttle } from "lodash";
import { useEffect } from "react";

type CallbackFunction = () => void;

const useThrottledMousePos = (callback: (e: any) => any, delay: number) => {
    const throttledCallback = throttle(callback, delay);

    useEffect(() => {
        window.addEventListener("mousemove", throttledCallback);
        return () => {
            window.removeEventListener("mousemove", throttledCallback);
        };
    }, [throttledCallback]);
};

export default useThrottledMousePos;
