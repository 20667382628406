/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EditMetaInput } from '../models/EditMetaInput';
import type { Meta } from '../models/Meta';
import type { MetaWithPhotos } from '../models/MetaWithPhotos';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetaService {

    /**
     * Get meta
     * @returns MetaWithPhotos
     * @throws ApiError
     */
    public static metaControllerGetMeta(): CancelablePromise<MetaWithPhotos> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/meta',
        });
    }

    /**
     * Edit meta
     * @param formData
     * @returns Meta
     * @throws ApiError
     */
    public static metaControllerMetaEdit(
        formData: EditMetaInput,
    ): CancelablePromise<Meta> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/meta',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
