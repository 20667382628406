import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    Grow,
    Paper,
    Snackbar,
    Switch,
    Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { EditMetaInput, Meta, MetaService, Photo } from "../../client/generated";
import { configInstance } from "../../config";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { OrEmptyStrings } from "../../types";
import { adminTheme } from "../App";
import { PhotoPreviewBackdrop, PhotoPreviewBackdropState } from "./PhotoPreviewBackdrop";
import { TinyEditor } from "./TinyEditor";

const defaultEditMetaFormData: Partial<OrEmptyStrings<EditMetaInput>> = {
    menuSocialText: "",
    menuSocialUrl: "",
    aboutText: "",
    aboutShotout: "",
    contactText: "",
    locationCurrent: "",
    locationNext: "",
    imprintText: "",
    isSiteDisabled: "",
    photo: undefined,
};

type Props = {};

async function photoEntityToPhotoFile(photo: Photo): Promise<File> {
    const res = await axios.get(configInstance.urls.storage + photo.path, {
        responseType: "blob",
    });
    const file = new File([res.data], photo.filename);
    return file;
}

export const Settings: React.FC<Props> = () => {
    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);
    const { enqueueSnackbar } = useSnackbar();

    const [meta, setMeta] = React.useState<Meta>();
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);
    const [editMetaFormData, setEditMetaFormData] = React.useState<Partial<OrEmptyStrings<EditMetaInput>>>(defaultEditMetaFormData);
    const [saveBtnDisabled, setSaveBtnDisabled] = React.useState<boolean>(false);
    const [tinyEditorLoading, setTinyEditorLoading] = React.useState<boolean>(true);
    const [tinyEditorIgnoreUnsavedChanges, setTinyEditorIgnoreUnsavedChanges] = React.useState<{
        aboutText: boolean;
        contactText: boolean;
        imprintText: boolean;
    }>({ aboutText: true, contactText: true, imprintText: true });

    const [photoPreviewBackdrop, setPhotoPreviewBackdrop] = React.useState<PhotoPreviewBackdropState>({
        src: "",
        visible: false,
    });

    const onEditMetaFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setSaveBtnDisabled(true);

        console.log(editMetaFormData.isSiteDisabled);

        MetaService.metaControllerMetaEdit({
            menuSocialText: editMetaFormData.menuSocialText as string,
            menuSocialUrl: editMetaFormData.menuSocialUrl as string,
            aboutText: editMetaFormData.aboutText as string,
            aboutShotout: editMetaFormData.aboutShotout as string,
            contactText: editMetaFormData.contactText as string,
            locationCurrent: editMetaFormData.locationCurrent as string,
            locationNext: editMetaFormData.locationNext as string,
            isSiteDisabled: editMetaFormData.isSiteDisabled as string,
            imprintText: editMetaFormData.imprintText as string,
            photo: editMetaFormData.photo as File,
        })
            .then((_newMeta: Meta) => {
                setHasUnsavedChanges(false);
                setEditMetaFormData(editMetaFormData);
                enqueueSnackbar(<Typography variant="subtitle1">Saved.</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch((err: any) => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            })
            .finally(() => {
                setTimeout(() => setSaveBtnDisabled(false), 2000);
            });
    };

    const onEditMetaFormSubmitError = (e: any) => {
        enqueueSnackbar(<Typography variant="subtitle1">{JSON.stringify(e)}</Typography>, {
            variant: "error",
            autoHideDuration: 2000,
        });
    };

    const onEditMetaFormFieldUpdate = (event: any) => {
        if (!event.tinyEditorIgnoreUnsavedChanges) {
            setHasUnsavedChanges(true);
        }

        setEditMetaFormData({
            ...editMetaFormData,
            [event.target.name as keyof EditMetaInput]: event.target.value,
        });
    };

    const onEditMetaFormFileInputUpdate = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (event) {
            setHasUnsavedChanges(true);
            const photoFile = event.target.files ? Array.from(event.target.files)[0] : null;
            if (photoFile) {
                setEditMetaFormData({ ...editMetaFormData, photo: photoFile });
            }
        }
    };

    const onEditMetaFormSwitchUpdate = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setHasUnsavedChanges(true);
        setEditMetaFormData({
            ...editMetaFormData,
            [event.target.name as keyof EditMetaInput]: checked ? "true" : "false",
        });
    };

    useEffect(() => {
        if (meta?.aboutPhoto) {
            photoEntityToPhotoFile(meta.aboutPhoto).then(photoFile => {
                setEditMetaFormData({ ...editMetaFormData, photo: photoFile });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meta]);

    useEffect(() => {
        setTitle("admin | settings");
        setNavBarKey("settings");

        MetaService.metaControllerGetMeta()
            .then(metaWithphotos => {
                setMeta(metaWithphotos.meta);
                setEditMetaFormData({
                    menuSocialUrl: metaWithphotos.meta.menuSocialUrl ?? "",
                    menuSocialText: metaWithphotos.meta.menuSocialText ?? "",
                    aboutText: metaWithphotos.meta.aboutText,
                    aboutShotout: metaWithphotos.meta.aboutShotout ?? "",
                    contactText: metaWithphotos.meta.contactText,
                    locationCurrent: metaWithphotos.meta.locationCurrent ?? "",
                    locationNext: metaWithphotos.meta.locationNext ?? "",
                    isSiteDisabled: metaWithphotos.meta.isSiteDisabled ? "true" : "false",
                    imprintText: metaWithphotos.meta.imprintText,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PhotoPreviewBackdrop state={photoPreviewBackdrop} setState={setPhotoPreviewBackdrop} />
            {hasUnsavedChanges ? (
                <Snackbar open={true} anchorOrigin={{ vertical: "top", horizontal: "right" }} sx={{ mr: 22, mt: -2 }}>
                    <Alert severity="warning" variant="filled">
                        Unsaved changes
                    </Alert>
                </Snackbar>
            ) : null}

            <Grid container sx={{ justifyContent: "center" }}>
                {meta ? (
                    <Grow in>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ pt: 2, backgroundColor: "#fff" }}>
                                <ValidatorForm
                                    onSubmit={onEditMetaFormSubmit}
                                    onError={onEditMetaFormSubmitError}
                                    sx={{ display: "flex", flexDirection: "column", gap: 22 }}
                                >
                                    <Grid container spacing={2} sx={{ px: 2, pb: 2 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Disable site</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name={"isSiteDisabled"}
                                                        checked={editMetaFormData.isSiteDisabled === "true"}
                                                        onChange={onEditMetaFormSwitchUpdate}
                                                    />
                                                }
                                                label="If set to true, site will be disabled to the public."
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Location</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                Text visible at the middle-top of a webpage that tells your current and next location. If
                                                you leave a field empty, it won't be shown.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextValidator
                                                fullWidth
                                                name={"locationCurrent" as keyof EditMetaInput}
                                                label="Current"
                                                variant="outlined"
                                                onChange={onEditMetaFormFieldUpdate}
                                                value={editMetaFormData.locationCurrent}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextValidator
                                                fullWidth
                                                name={"locationNext" as keyof EditMetaInput}
                                                label="Next"
                                                variant="outlined"
                                                onChange={onEditMetaFormFieldUpdate}
                                                value={editMetaFormData.locationNext}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography variant="h6" sx={{ mt: 2 }}>
                                                About
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Text visible on the "ABOUT" page.</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TinyEditor
                                                isLoading={tinyEditorLoading}
                                                setIsLoading={setTinyEditorLoading}
                                                onEditorChange={value => {
                                                    onEditMetaFormFieldUpdate({
                                                        target: { name: "aboutText" as keyof EditMetaInput, value },
                                                        tinyEditorIgnoreUnsavedChanges: tinyEditorIgnoreUnsavedChanges.aboutText,
                                                    });
                                                    setTinyEditorIgnoreUnsavedChanges({
                                                        ...tinyEditorIgnoreUnsavedChanges,
                                                        aboutText: false,
                                                    });
                                                }}
                                                value={editMetaFormData.aboutText}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Text visible under the photo on the "ABOUT" page.</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                name={"aboutShotout" as keyof EditMetaInput}
                                                label="Shotout"
                                                variant="outlined"
                                                onChange={onEditMetaFormFieldUpdate}
                                                value={editMetaFormData.aboutShotout}
                                                validators={["required"]}
                                                errorMessages={["Field is required"]}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Photo visible on the "ABOUT" page.</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Paper
                                                elevation={1}
                                                sx={{
                                                    backgroundColor: adminTheme.palette.grey[50],
                                                }}
                                            >
                                                <Grid container sx={{ alignItems: "center" }}>
                                                    {editMetaFormData.photo ? (
                                                        <Grid
                                                            item
                                                            onClick={() =>
                                                                setPhotoPreviewBackdrop({
                                                                    visible: true,
                                                                    src: URL.createObjectURL(editMetaFormData.photo as Blob),
                                                                })
                                                            }
                                                        >
                                                            <Avatar
                                                                className="hoverPointer"
                                                                sx={{ borderRadius: 0, m: 1 }}
                                                                alt=" "
                                                                src={URL.createObjectURL(editMetaFormData.photo as Blob)}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        <Grid item sx={{ m: 1, ml: 2 }}>
                                                            <Typography variant="body1">No photo uploaded yet.</Typography>{" "}
                                                        </Grid>
                                                    )}

                                                    <Grid item sx={{ flexGrow: 2 }}>
                                                        <Grid container>
                                                            <Grid item sx={{ width: "100%" }}>
                                                                <Typography variant="body1">
                                                                    {/* {JSON.stringify(photoFilePair)} */}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sx={{ m: 1, mr: 2 }}>
                                                        <Button variant="contained" component="label">
                                                            Upload photo
                                                            <input
                                                                type="file"
                                                                hidden
                                                                accept="image/png, image/gif, image/jpeg"
                                                                onChange={onEditMetaFormFileInputUpdate}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography variant="h6">Menu text</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                Text visible on the "MENU" page in the corner, preferably an Instagram URL.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextValidator
                                                fullWidth
                                                name={"menuSocialText" as keyof EditMetaInput}
                                                label="Text"
                                                variant="outlined"
                                                onChange={onEditMetaFormFieldUpdate}
                                                value={editMetaFormData.menuSocialText}
                                                validators={["required"]}
                                                errorMessages={["Field is required"]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextValidator
                                                fullWidth
                                                name={"menuSocialUrl" as keyof EditMetaInput}
                                                label="Url"
                                                variant="outlined"
                                                onChange={onEditMetaFormFieldUpdate}
                                                value={editMetaFormData.menuSocialUrl}
                                                validators={["required"]}
                                                errorMessages={["Field is required"]}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography variant="h6" sx={{ mt: 2 }}>
                                                Contact
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Text visible on the "CONTACT" page.</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TinyEditor
                                                isLoading={tinyEditorLoading}
                                                setIsLoading={setTinyEditorLoading}
                                                onEditorChange={value => {
                                                    onEditMetaFormFieldUpdate({
                                                        target: { name: "contactText" as keyof EditMetaInput, value },
                                                        tinyEditorIgnoreUnsavedChanges: tinyEditorIgnoreUnsavedChanges.contactText,
                                                    });
                                                    setTinyEditorIgnoreUnsavedChanges({
                                                        ...tinyEditorIgnoreUnsavedChanges,
                                                        contactText: false,
                                                    });
                                                }}
                                                value={editMetaFormData.contactText}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography variant="h6" sx={{ mt: 2 }}>
                                                Imprint
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">
                                                Text visible on the "IMPRINT AND DATA PROTECTION" page.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TinyEditor
                                                isLoading={tinyEditorLoading}
                                                setIsLoading={setTinyEditorLoading}
                                                onEditorChange={value => {
                                                    onEditMetaFormFieldUpdate({
                                                        target: { name: "imprintText" as keyof EditMetaInput, value },
                                                        tinyEditorIgnoreUnsavedChanges: tinyEditorIgnoreUnsavedChanges.imprintText,
                                                    });
                                                    setTinyEditorIgnoreUnsavedChanges({
                                                        ...tinyEditorIgnoreUnsavedChanges,
                                                        imprintText: false,
                                                    });
                                                }}
                                                value={editMetaFormData.imprintText}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 3 }} />
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <LoadingButton
                                                    disabled={saveBtnDisabled}
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={<SaveIcon />}
                                                    color="success"
                                                    sx={{ mr: 1 }}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Paper>
                        </Grid>
                    </Grow>
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        </>
    );
};
