import { Box, LinearProgress } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React from "react";

export type Props = {
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    value: string | undefined;
    onEditorChange: (a: string) => void;
};

export const TinyEditor: React.FC<Props> = props => {
    return (
        <React.Fragment>
            <Box>
                {props.isLoading && <LinearProgress />}
                <Editor
                    apiKey="zyxuyey4h5l70077nzsnciby14sfusoza4c5b7d00r62zs6z"
                    onEditorChange={props.onEditorChange}
                    onInit={() => props.setIsLoading(false)}
                    init={{
                        advcode_inline: true,
                        content_style: `
                            a {
                                text-decoration: none;
                                color: unset;
                            }
                            
                            h1,h2,h3,h4,h5,h6,button {
                                font-family:Arial,Helvetica,sans-serif;
                                font-weight:700;
                                letter-spacing:-.34px;
                                color:#39ff14;
                                margin: 0;
                            }
                            
                            h1,h2,h3 {
                                text-transform:uppercase
                            }
                            
                            h1,h2 {
                                font-size:67pt
                            }
                            
                            h3 {
                                font-size:37pt
                            }
                            
                            h4 {
                                font-size:28pt
                            }
                            
                            h5 {
                                font-size:18pt
                            }
                            
                            h6 {
                                font-size:12pt
                            }

                            .mobile-break {
                                color: red
                            }
                        `,
                        style_formats: [
                            { title: "MUI H2", block: "h2", classes: "MuiTypography-root MuiTypography-h2" },
                            { title: "MUI H3", block: "h3", classes: "MuiTypography-root MuiTypography-h3" },
                            { title: "MUI H4", block: "h4", classes: "MuiTypography-root MuiTypography-h4" },
                            { title: "MUI H5", block: "h5", classes: "MuiTypography-root MuiTypography-h5" },
                            { title: "MUI H6", block: "h6", classes: "MuiTypography-root MuiTypography-h6" },
                            { title: "MOBILE BREAK", block: "h6", classes: "MuiTypography-root MuiTypography-h6 mobile-break" },
                        ],
                        plugins: "link stylebuttons code",
                        toolbar: "undo redo | align | bold italic underline forecolor backcolor | styles | link removeformat | code",
                        menubar: false,
                        branding: false,
                    }}
                    value={props.value}
                />
            </Box>
        </React.Fragment>
    );
};
