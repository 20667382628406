import { Dashboard, LogoutOutlined } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CollectionsIcon from "@mui/icons-material/Collections";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PublicIcon from "@mui/icons-material/Public";
import SettingsIcon from "@mui/icons-material/Settings";
import { Backdrop, Badge, Divider, List, Tooltip } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Link } from "react-router-dom";
import store from "store";
import { AuthContext } from "../../context/AuthContext";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { adminTheme } from "./../App";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import MuseumIcon from "@mui/icons-material/Museum";

export type NavBarKeys = "dashboard" | "work" | "publication" | "settings" | "exhibition";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    position: "fixed",

    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const drawerWidth: number = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== "open" })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        zIndex: theme.zIndex.drawer - 1,
        position: "fixed",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        // backgroundColor: lightGreen[50],

        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

export function NavBar() {
    const [isDrawerOpen, setDrawerOpen] = React.useState<boolean>(store.get("isDrawerOpen") === true);
    const toggleDrawer = () => {
        store.set("isDrawerOpen", !isDrawerOpen); // todo: add typing, avoid string
        setDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer = () => {
        store.set("isDrawerOpen", false); // todo: add typing, avoid string
        setDrawerOpen(false);
    };

    const { logout } = React.useContext(AuthContext);
    const { title, navBarKey } = React.useContext(CurrentPageContext);

    return (
        <React.Fragment>
            <AppBar position="absolute" open={isDrawerOpen}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={toggleDrawer}
                        sx={{
                            mr: 4,
                            transition: "opacity 300ms",
                            ...(isDrawerOpen && { opacity: 0 }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, ml: isDrawerOpen ? -7 : 0, transition: "margin 300ms" }}
                    >
                        {title}
                    </Typography>
                    <Tooltip title="Web design page">
                        <Link to="/webdesign">
                            <IconButton color="inherit">
                                <DesignServicesIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Public page">
                        <Link to="/">
                            <IconButton color="inherit">
                                <PublicIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="User settings">
                        <Link to="/admin/user-settings">
                            <IconButton color="inherit">
                                <ManageAccountsIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Log out">
                        <IconButton color="inherit" onClick={logout}>
                            <LogoutOutlined />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={isDrawerOpen} BackdropProps={{ style: { backgroundColor: "red", opacity: 2 } }}>
                <Toolbar
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: [1],
                    }}
                >
                    <Typography sx={{ mx: 2, fontWeight: "bold" }}>gerd</Typography>

                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav" sx={{ py: 0 }}>
                    <Link to="/admin/dashboard" onClick={closeDrawer}>
                        <Tooltip placement="right" title={isDrawerOpen ? null : "Dashboard"}>
                            <ListItemButton
                                sx={{
                                    backgroundColor: navBarKey === "dashboard" ? adminTheme.palette.action.hover : "unset",
                                }}
                            >
                                <ListItemIcon>
                                    <Dashboard sx={{ mt: { sm: 0.5 }, ml: { sm: 0.5 } }} />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </Tooltip>
                    </Link>
                    <Link to="/admin/works" onClick={closeDrawer}>
                        <Tooltip placement="right" title={isDrawerOpen ? null : "Works"}>
                            <ListItemButton sx={{ backgroundColor: navBarKey === "work" ? adminTheme.palette.action.hover : "unset" }}>
                                <ListItemIcon>
                                    <CollectionsIcon sx={{ mt: { sm: 0.5 }, ml: { sm: 0.5 } }} />
                                </ListItemIcon>
                                <ListItemText primary="Works" />
                            </ListItemButton>
                        </Tooltip>
                    </Link>
                    <Link to="/admin/publications" onClick={closeDrawer}>
                        <Tooltip placement="right" title={isDrawerOpen ? null : "Publications"}>
                            <ListItemButton
                                sx={{
                                    backgroundColor: navBarKey === "publication" ? adminTheme.palette.action.hover : "unset",
                                }}
                            >
                                <ListItemIcon>
                                    <Badge color="primary">
                                        <NewspaperIcon sx={{ mt: { sm: 0.5 }, ml: { sm: 0.5 } }} />
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText primary="Publications" />
                            </ListItemButton>
                        </Tooltip>
                    </Link>
                    <Link to="/admin/exhibitions" onClick={closeDrawer}>
                        <Tooltip placement="right" title={isDrawerOpen ? null : "Exhibitions"}>
                            <ListItemButton
                                sx={{
                                    backgroundColor: navBarKey === "exhibition" ? adminTheme.palette.action.hover : "unset",
                                }}
                            >
                                <ListItemIcon>
                                    <Badge color="primary">
                                        <MuseumIcon sx={{ mt: { sm: 0.5 }, ml: { sm: 0.5 } }} />
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText primary="Exhibitions" />
                            </ListItemButton>
                        </Tooltip>
                    </Link>
                    <Link to="/admin/settings" onClick={closeDrawer}>
                        <Tooltip placement="right" title={isDrawerOpen ? null : "Settings"}>
                            <ListItemButton
                                sx={{
                                    backgroundColor: navBarKey === "settings" ? adminTheme.palette.action.hover : "unset",
                                }}
                            >
                                <ListItemIcon>
                                    <SettingsIcon sx={{ mt: { sm: 0.5 }, ml: { sm: 0.5 } }} />
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItemButton>
                        </Tooltip>
                    </Link>
                </List>
            </Drawer>
            <Backdrop
                sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer - 2 }}
                open={isDrawerOpen}
                onClick={toggleDrawer}
            ></Backdrop>
        </React.Fragment>
    );
}
