import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CurrentPageContext } from "../../context/CurrentPageContext";

type Props = {};

const testText = "But this is regularly interrupted by warning sirens. Missiles inbound.";

export const WebDesign: React.FC<Props> = () => {
    const { setTitle } = React.useContext(CurrentPageContext);

    useEffect(() => {
        setTitle("web design");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Box>
                <Typography variant="h1">{testText}</Typography>

                <Divider sx={{ my: 2 }} />
                <Typography variant="h2">{testText}</Typography>

                <Divider sx={{ my: 2 }} />
                <Typography variant="h3">{testText}</Typography>

                <Divider sx={{ my: 2 }} />
                <Typography variant="h4">{testText}</Typography>

                <Divider sx={{ my: 2 }} />
                <Typography variant="h4">{testText}</Typography>

                <Divider sx={{ my: 2 }} />
                <Typography variant="h5">{testText}</Typography>

                <Divider sx={{ my: 2 }} />
                <Typography variant="h6">{testText}</Typography>

                <Divider sx={{ my: 2 }} />
                <Typography variant="body1">{testText}</Typography>

                <Divider sx={{ my: 2 }} />
                <Button variant="contained">{testText}</Button>

                <Divider sx={{ my: 2 }} />
                <Button variant="outlined">{testText}</Button>

                <Divider sx={{ my: 2 }} />
                <Button variant="text">{testText}</Button>
            </Box>
        </React.Fragment>
    );
};
