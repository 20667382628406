import { Box, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import htmlReactParser from "html-react-parser";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { PublicationService } from "../../client/generated";
import { configInstance } from "../../config";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { getPathBasedOnWindowWidth } from "../../helpers/getPathBasedOnWindowWidth";
import { publicTheme } from "../App";
import { ScrollToTopButton } from "./ScrollToTopButton";
import { TitleAndBackBtn } from "./TitleAndBackBtn";

type Props = {};

export const PublicationsList: React.FC<Props> = () => {
    const { setTitle } = React.useContext(CurrentPageContext);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { width: windowWidth } = useWindowSize();

    const { data: publications } = useQuery({
        queryKey: ["publications"],
        queryFn: async () => {
            setIsLoading(true);

            const res = await PublicationService.publicationControllerPublicationList(
                0,
                1000,
                JSON.stringify({ $and: [{ isActive: true }] }),
                JSON.stringify({ index: "ASC" }),
            );

            setTimeout(() => setIsLoading(false), 500);
            return res.result;
        },
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5,
        staleTime: 0,
    });

    useEffect(() => {
        setTitle(`publications`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <ScrollToTopButton />

            <TitleAndBackBtn
                position="fixed"
                titleEl={
                    <Grid item sx={{ display: "flex", alignContent: "center", flexWrap: "wrap" }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: "50px!important;",
                                [publicTheme.breakpoints.down("lg")]: {
                                    fontSize: "44px!important;",
                                },
                            }}
                        >
                            PUBLICATIONS
                        </Typography>
                    </Grid>
                }
                backLink="/"
            />

            <Grid
                container
                sx={{
                    mt: 22,
                    minHeight: "100vh",
                    alignContent: "flex-start",
                    [publicTheme.breakpoints.between("sm", "lg")]: {
                        mt: "100px",
                    },
                    [publicTheme.breakpoints.down("sm")]: {
                        mt: 0,
                        mx: 1,
                        width: `calc(100% - 16px)`,
                    },
                }}
            >
                {publications
                    ? publications.map((publication, i) => (
                          <Grid
                              container
                              sx={{
                                  mb: 8,
                                  [publicTheme.breakpoints.down("sm")]: {
                                      mt: i === 0 ? "120px" : 0,
                                  },
                              }}
                          >
                              <Grid
                                  item
                                  sx={{
                                      position: "absolute",
                                      right: `-20px`,
                                      width: "50%",

                                      [publicTheme.breakpoints.down("lg")]: {
                                          width: "100%",
                                      },
                                  }}
                                  className="hover-pointer"
                                  //   onClick={() => (publication.url ? window.open(publication.url, "_blank", "noopener, noreferrer") : null)}
                              >
                                  <Link to={publication.url ? publication.url : ""} target="_blank" rel="noopener noreferrer">
                                      <Box>{publication.titleFancy ? htmlReactParser(publication.titleFancy) : null}</Box>
                                  </Link>
                              </Grid>
                              <Grid item sx={{ width: "100%", textAlign: "right" }}>
                                  <Link to={publication.url ? publication.url : ""} target="_blank" rel="noopener noreferrer">
                                      <Box
                                          id={`work_${publication.index}_img`}
                                          component="img"
                                          loading="lazy"
                                          src={
                                              configInstance.urls.storage +
                                              getPathBasedOnWindowWidth(publication.photo?.path ? publication.photo.path : "", windowWidth)
                                          }
                                          alt={publication.id + "_alt"}
                                          sx={{
                                              width: "100%",
                                              height: "auto",
                                              maxWidth: `calc(50vw - ${publicTheme.spacing(4)})`,
                                              maxHeight: "100%",
                                              [publicTheme.breakpoints.down("lg")]: {
                                                  maxWidth: `100vw`,
                                              },
                                          }}
                                          className="hover-pointer"
                                          //   onClick={() =>
                                          //       publication.url ? window.open(publication.url, "_blank", "noopener, noreferrer") : null
                                          //   }
                                      ></Box>
                                  </Link>
                              </Grid>
                          </Grid>
                      ))
                    : null}
            </Grid>
            <Grid container sx={{ mt: 8 }}>
                <Grid item sx={{ ml: 7, mb: 0.6 }}>
                    <Link to="/imprint">
                        <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                            IMPRINT & DATA PROTECTION
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
