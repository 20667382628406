import { Box, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { motion, useScroll, useTransform } from "framer-motion";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useIntersectionObserver, useWindowSize } from "usehooks-ts";
import { DisplayFormat, Photo, Work, WorkService } from "../../client/generated";
import { configInstance } from "../../config";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { getPathBasedOnWindowWidth } from "../../helpers/getPathBasedOnWindowWidth";
import { publicTheme } from "../App";
import { Location } from "./Location";
import { ScrollToTopButton } from "./ScrollToTopButton";

type Props = {};
type WorksViewportVisibility = { [x in string]: boolean };

const getMaxWidthForDisplayFormat = (displayFormat: DisplayFormat) => {
    switch (displayFormat) {
        case DisplayFormat.SMALL:
            return "40%";
        case DisplayFormat.MEDIUM:
            return "60%";
        case DisplayFormat.LARGE:
            return "80%";
        case DisplayFormat.XLARGE:
            return "100%";
        default:
            return "100%";
    }
};

const WorkSection = (props: {
    work: Work;
    isLoading: boolean;
    worksViewportVisibility: WorksViewportVisibility;
    setWorksViewportVisibility: React.Dispatch<React.SetStateAction<WorksViewportVisibility>>;
    windowWidth: number;
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {});
    const isVisible = !!entry?.isIntersecting;

    useEffect(() => {
        props.setWorksViewportVisibility({ ...props.worksViewportVisibility, [`work_${props.work.index}`]: isVisible });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry]);

    return (
        <Grid
            container
            sx={{
                pl: 2,
                [publicTheme.breakpoints.between("sm", "lg")]: {
                    position: "relative",
                    //  marginBottom: "-290px"
                },
                [publicTheme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    pl: 0,
                    mx: 1,
                    width: "calc(100% - 16px)",
                },
            }}
            ref={ref}
            id={`work_${props.work.index}`}
        >
            <Grid
                item
                sx={{
                    alignSelf: "flex-start",
                    position: "sticky",
                    top: "-25px",
                    width: "30%",
                    zIndex: 1000,
                    [publicTheme.breakpoints.between("sm", "lg")]: { top: "5px" },
                    // [publicTheme.breakpoints.down("lg")]: { top: "75px" },
                    [publicTheme.breakpoints.down("sm")]: { position: "relative", top: 0, width: "100%" },
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        width: "fit-content",
                        mt: 12,
                        [publicTheme.breakpoints.down("sm")]: { mt: 0, mb: 4 },
                    }}
                >
                    <Link to={`/work/${props.work.url}`}>
                        <Typography
                            className="a-strike"
                            variant="h2"
                            sx={{
                                [publicTheme.breakpoints.down("sm")]: {
                                    fontSize: "40px!important;",
                                },
                            }}
                        >
                            {props.work.title}
                        </Typography>
                    </Link>
                </Box>
            </Grid>
            <Grid
                item
                sx={{
                    [publicTheme.breakpoints.between("sm", "lg")]: { width: "100%", marginTop: "-290px" },
                    [publicTheme.breakpoints.down("sm")]: { width: "100%" },
                    width: "70%",
                    textAlign: "right",
                    mb: 14,
                    position: "relative",
                    height: "inherit",
                }}
            >
                <Link to={`/work/${props.work.url}`}>
                    <Box
                        id={`work_${props.work.index}a`}
                        component="img"
                        loading="lazy"
                        src={
                            props.work.mainPhoto?.path
                                ? configInstance.urls.storage + getPathBasedOnWindowWidth(props.work.mainPhoto.path, props.windowWidth)
                                : ""
                        }
                        alt={props.work.id + "_alt"}
                        sx={{
                            [publicTheme.breakpoints.up("sm")]: {
                                maxWidth: getMaxWidthForDisplayFormat(
                                    (props.work.mainPhotoDisplayFormat as DisplayFormat) || DisplayFormat.XLARGE,
                                ),
                            },
                            // [publicTheme.breakpoints.between("sm", "lg")]: { maxWidth: "80%" },
                            [publicTheme.breakpoints.down("sm")]: { maxWidth: "100%" },
                            // height: "100%",
                            width: "auto",
                            maxWidth: "100%",
                            // maxHeight: `calc(100vh - ${publicTheme.spacing(4)})`,
                        }}
                    ></Box>
                </Link>
            </Grid>
        </Grid>
    );
};

export const Works: React.FC<Props> = () => {
    // const [scrollTop, setScrollTop] = React.useState(0);
    const { width: windowWidth } = useWindowSize();
    const { scrollY } = useScroll();
    const y1 = useTransform(
        scrollY,
        [0, 500], // scroll range
        [-100, 0], // title element position range
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_nextViewportWork, setNextViewportWork] = React.useState<string>("");
    const [slideshowCurrentPhotoUrl, setSlideshowCurrentPhotoUrl] = React.useState<string>();
    const [slideshowPhotos, setSlideshowPhotos] = React.useState<Photo[]>([]);
    const [slideshowInterval, setSlideshowInterval] = React.useState<NodeJS.Timer>();
    const [worksViewportVisibility, setWorksViewportVisibility] = React.useState<WorksViewportVisibility>({});
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);

    const { data: activeWorks } = useQuery({
        queryKey: ["works"],
        queryFn: async () => {
            setIsLoading(true);

            const res = await WorkService.workControllerWorkList(
                0,
                1000,
                JSON.stringify({ $and: [{ isActive: true }] }),
                JSON.stringify({ index: "ASC" }),
            );

            const photosToSlideshow: Photo[] = [];
            for (const work of res.result) {
                if (work.photos) {
                    work.photos.sort((a, b) => a.index - b.index);
                    photosToSlideshow.push(...work.photos.filter(p => p.isVisibleOnMain));
                }
            }

            setTimeout(() => setIsLoading(false), 500);
            setSlideshowPhotos(_.shuffle(photosToSlideshow));
            return res.result;
        },
        initialData: [],
        // staleTime: 1000 * 60 * 5,

        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5,
        staleTime: 0,
    });

    const handleFirstWorkScroll = () => {
        window.scrollBy(0, window.innerHeight);
    };

    useEffect(() => {
        clearInterval(slideshowInterval);
        let i = 0;

        if (slideshowPhotos.length > 0) {
            const interval = setInterval(() => {
                const photo = slideshowPhotos[i];
                setSlideshowCurrentPhotoUrl(configInstance.urls.storage + getPathBasedOnWindowWidth(photo.path, windowWidth));

                if (i === slideshowPhotos.length - 1) {
                    i = 0;
                } else {
                    i++;
                }
            }, 300);
            setSlideshowInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slideshowPhotos, windowWidth]);

    useEffect(() => {
        let props = Object.keys(worksViewportVisibility).reverse();

        for (const prop of props) {
            if (worksViewportVisibility[prop]) {
                setNextViewportWork(prop);
            }
        }
    }, [worksViewportVisibility]);

    useEffect(() => {
        setTitle("works");
        setNavBarKey("work");

        // handle scroll listener

        // const handleScroll = (_event: any) => {
        //     setScrollTop(window.scrollY);
        // };
        // window.addEventListener("scroll", handleScroll);
        // return () => {
        //     window.removeEventListener("scroll", handleScroll);
        // };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {/* <Loading isLoading={isLoading} /> */}
            <ScrollToTopButton />

            <Grid
                container
                sx={{
                    position: "fixed",
                    zIndex: 105,
                    mt: 2,
                    [publicTheme.breakpoints.down("sm")]: {
                        position: "absolute",
                    },
                }}
            >
                <Grid
                    item
                    sx={{
                        [publicTheme.breakpoints.down("lg")]: {
                            marginLeft: "17px",
                        },
                        [publicTheme.breakpoints.down("sm")]: {
                            mt: 0,
                            ml: "10px",
                        },
                    }}
                >
                    <Location />
                </Grid>
            </Grid>

            <Grid
                container
                sx={{
                    position: "fixed",
                    height: `fit-content`,
                    alignContent: "space-between",
                    [publicTheme.breakpoints.between("sm", "lg")]: {
                        zIndex: 200,
                        marginTop: "30px",
                    },
                    [publicTheme.breakpoints.down("sm")]: {
                        mt: "0px",
                        ml: "-8px",
                        width: 200,
                        zIndex: 101,
                    },
                }}
            >
                <motion.div
                    transition={{
                        type: "linear",
                        duration: 1,
                    }}
                    style={{ y: y1 }}
                >
                    <Grid item sx={{ width: "100%", mt: "10px", ml: 2 }}>
                        <Typography
                            variant="h4"
                            sx={{
                                [publicTheme.breakpoints.down("sm")]: {
                                    lineHeight: 1,
                                },
                            }}
                        >
                            GERD WALISZEWSKI
                        </Typography>
                    </Grid>
                </motion.div>
            </Grid>
            <Grid
                container
                sx={{
                    height: `calc(100vh - ${publicTheme.spacing(4)})`,
                    ml: 2,
                    mt: 1 + 0.5,
                    mb: 8,
                    alignContent: "space-between",
                    zIndex: 100,
                    position: "relative",

                    [publicTheme.breakpoints.down("sm")]: {
                        ml: 1,
                        mt: 0,
                        height: `calc(100vh - ${publicTheme.spacing(2)})`,
                    },
                    // [publicTheme.breakpoints.down("sm")]: {
                    //     fontSize: "44px!important;",
                    //     mt: 0,
                    //     ml: 0,
                    // },
                }}
            >
                <Grid
                    item
                    sx={{
                        height: "fit-content",
                        width: "100%",
                        zIndex: 100,
                        wordBreak: "break-word",
                        [publicTheme.breakpoints.down("lg")]: {
                            mt: "60px",
                        },
                    }}
                >
                    <Box>
                        <Typography
                            variant="h2"
                            sx={{
                                [publicTheme.breakpoints.down("sm")]: {
                                    fontSize: "40px!important;",
                                },
                            }}
                        >
                            GERD WALISZEWSKI
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="h2"
                            sx={{
                                [publicTheme.breakpoints.down("sm")]: {
                                    fontSize: "40px!important;",
                                },
                            }}
                        >
                            PHOTOGRAPHER
                        </Typography>
                    </Box>
                    <Box sx={{ position: "relative", width: "fit-content" }}>
                        <Link to="/about">
                            <Typography
                                className="a-strike"
                                variant="h2"
                                sx={{
                                    [publicTheme.breakpoints.down("sm")]: {
                                        fontSize: "40px!important;",
                                    },
                                }}
                            >
                                ABOUT
                            </Typography>
                        </Link>
                    </Box>
                    <Box sx={{ position: "relative", width: "fit-content" }}>
                        <Link to="/publications">
                            <Typography
                                className="a-strike"
                                variant="h2"
                                sx={{
                                    [publicTheme.breakpoints.down("sm")]: {
                                        fontSize: "40px!important;",
                                    },
                                }}
                            >
                                PUBLICATIONS
                            </Typography>
                        </Link>
                        <Typography variant="h5"></Typography>
                    </Box>
                    <Box sx={{ position: "relative", width: "fit-content" }}>
                        <Link to="/exhibitions">
                            <Typography
                                className="a-strike"
                                variant="h2"
                                sx={{
                                    [publicTheme.breakpoints.down("sm")]: {
                                        fontSize: "40px!important;",
                                    },
                                }}
                            >
                                EXHIBITIONS
                            </Typography>
                        </Link>
                        <Typography variant="h5"></Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    sx={{
                        height: "fit-content",
                        zIndex: 100,
                    }}
                >
                    <Box
                        sx={{
                            position: "relative",
                            width: "fit-content",
                            mb: 1,
                            [publicTheme.breakpoints.down("sm")]: {
                                mb: -1,
                            },
                        }}
                    >
                        <Typography
                            variant="h2"
                            onClick={handleFirstWorkScroll}
                            sx={{
                                [publicTheme.breakpoints.down("sm")]: {
                                    fontSize: "40px!important;",
                                },
                            }}
                            className="hover-pointer a-strike"
                        >
                            WORKS
                        </Typography>
                    </Box>
                </Grid>

                <Grid
                    item
                    sx={{
                        position: "absolute",
                        [publicTheme.breakpoints.down("sm")]: { width: "calc(100% - 16px)", ml: 2, mb: 0, top: "unset", bottom: "35px" },
                        [publicTheme.breakpoints.between("sm", "lg")]: { width: "100%", top: "70px" },
                        width: "70%",
                        mb: 14,
                        right: publicTheme.spacing(2),
                        top: `-${publicTheme.spacing(1.5)}`,
                        textAlign: "right",
                        zIndex: -1,
                    }}
                >
                    <Box
                        component="img"
                        src={slideshowCurrentPhotoUrl}
                        alt={slideshowCurrentPhotoUrl}
                        // src={"http://local.com:6006/photos-dev/w2000_3b920cc8-ab85-42ee-8a1b-151914d5be21.webp"}
                        // alt={"http://local.com:6006/photos-dev/w2000_3b920cc8-ab85-42ee-8a1b-151914d5be21.webp"}
                        sx={{
                            [publicTheme.breakpoints.down("lg")]: { maxHeight: 600 },
                            // height: "100%",
                            width: "auto",
                            maxWidth: "100%",
                            maxHeight: `calc(100vh - ${publicTheme.spacing(4)})`,
                        }}
                    ></Box>
                </Grid>
            </Grid>
            {activeWorks?.map((work, i) => {
                return (
                    <WorkSection
                        key={work.id}
                        work={work}
                        isLoading={isLoading}
                        worksViewportVisibility={worksViewportVisibility}
                        setWorksViewportVisibility={setWorksViewportVisibility}
                        windowWidth={windowWidth}
                    />
                );
            })}
            <Grid container>
                <Grid
                    item
                    sx={{
                        ml: 8,
                        mb: 0.6,
                        position: "relative",
                        zIndex: 200,
                        [publicTheme.breakpoints.down("sm")]: {
                            mb: 1,
                            ml: 6,
                        },
                    }}
                >
                    <Link to="/imprint">
                        <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                            IMPRINT & DATA PROTECTION
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
