import { Box, CircularProgress, Grid, ThemeProvider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { UserRole } from "../types";
import { adminTheme, publicTheme } from "./App";
import { NavBar } from "./admin/NavBar";

type Props = {
    component: React.ReactNode;
    requiredRoles?: UserRole[];
};

export function RequireAuth({ component, requiredRoles }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { user, getUserRoles } = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState<boolean>(true);
    const location = useLocation();
    const navigate = useNavigate();

    const canUserAccess = () => {
        // if there's no required roles for the page, simply let user access the page
        if (!requiredRoles) {
            setLoading(false);
            return true;
        }

        // check whether user is logged in and navigate to login page if not
        if (!user) {
            enqueueSnackbar(<Typography variant="subtitle1">This page is available for logged in users only. Log in please</Typography>, {
                variant: "error",
                autoHideDuration: 4000,
            });
            setLoading(false);
            return navigate("/login");
        }

        // check whether user has required roles to access auth protected page
        const userHasRequiredRoles = requiredRoles.every(requiredRole => getUserRoles().includes(requiredRole));
        if (!userHasRequiredRoles) {
            enqueueSnackbar(<Typography variant="subtitle1">You don't have permissions to view this page.</Typography>, {
                variant: "error",
                autoHideDuration: 4000,
            });
            setLoading(false);
            return navigate("/login");
        }

        setLoading(false);
        return true;
    };

    useEffect(() => {
        canUserAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <ThemeProvider theme={adminTheme}>
            <Grid
                container
                width="100vw"
                sx={{
                    backgroundColor: adminTheme.palette.grey[100],
                }}
            >
                {user && <NavBar />}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        minHeight: `calc(100vh - ${adminTheme.spacing(12)})`,
                        mt: { xs: 7, sm: 8 },
                        ml: { xs: 7, sm: 9 },
                        p: { xs: 2 },
                        transition: adminTheme.transitions.create(["margin", "padding"], {
                            easing: adminTheme.transitions.easing.sharp,
                            duration: adminTheme.transitions.duration.enteringScreen,
                        }),
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        flexWrap: "wrap",
                    }}
                >
                    {loading ? <CircularProgress /> : component}
                </Box>
            </Grid>
        </ThemeProvider>
    );
}
