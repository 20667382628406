import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CurrentPageContext } from "../../context/CurrentPageContext";

type Props = {};

export type SomeItem = {
    id: number;
    name: string;
};

export const Dashboard: React.FC<Props> = () => {
    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);
    const { getDecodedAccessToken } = React.useContext(AuthContext);

    useEffect(() => {
        setTitle("admin | dashboard");
        setNavBarKey(undefined);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            flexFlow: "column",
                        }}
                    >
                        <Paper
                            elevation={2}
                            sx={{
                                p: 4,
                                backgroundColor: "#fff",
                            }}
                        >
                            <Typography variant="h2">Hi, {getDecodedAccessToken()?.user.username}</Typography>
                            <Typography variant="subtitle1">You're now logged in.</Typography>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};
