import { Backdrop, Box, alpha } from "@mui/material";
import React from "react";
import { adminTheme, publicTheme } from "../App";

export type PhotoPreviewBackdropState = {
    visible: boolean;
    src: string;
};

export type Props = {
    state: PhotoPreviewBackdropState;
    setState: React.Dispatch<React.SetStateAction<PhotoPreviewBackdropState>>;
};
export function PhotoPreviewBackdrop(props: Props) {
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 3, backgroundColor: alpha(adminTheme.palette.primary.main, 0.7) }}
            open={props.state.visible}
            onClick={() => props.setState({ src: "", visible: false })}
        >
            <Box
                component="img"
                sx={{
                    height: "90vh",
                    width: "auto",
                }}
                alt={props.state.src}
                src={props.state.src}
            />
        </Backdrop>
    );
}
