import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { MetaContext } from "../../context/MetaContext";
import { publicTheme } from "../App";

type Props = {};

export const Location: React.FC<Props> = () => {
    const { metaWithPhotos } = React.useContext(MetaContext);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            sx={{
                mr: 32,
                position: "fixed",
                justifyContent: "end",
                width: `calc(100vw - 250px)`,
                [publicTheme.breakpoints.down("lg")]: {
                    justifyContent: "start",
                },
                [publicTheme.breakpoints.down("md")]: {
                    width: `calc(100vw - 50px)`,
                },
                [publicTheme.breakpoints.down("sm")]: {
                    position: "absolute",
                    mr: 0,
                },
            }}
        >
            {metaWithPhotos?.meta ? (
                <Grid item>
                    {!metaWithPhotos.meta.locationCurrent || metaWithPhotos.meta.locationCurrent === "" ? null : (
                        <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                            CURRENTLY IN: {metaWithPhotos.meta.locationCurrent}
                        </Typography>
                    )}
                    {!metaWithPhotos.meta.locationNext || metaWithPhotos.meta.locationNext === "" ? null : (
                        <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                            NEXT: {metaWithPhotos.meta.locationNext}
                        </Typography>
                    )}
                </Grid>
            ) : null}
        </Grid>
    );
};
