import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import htmlReactParser from "html-react-parser";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { DisplayFormat, Work, WorkService } from "../../client/generated";
import { configInstance } from "../../config";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { getPathBasedOnWindowWidth } from "../../helpers/getPathBasedOnWindowWidth";
import { getPathSm } from "../../helpers/getPathSm";
import { publicTheme } from "../App";
import { ScrollToTopButton } from "./ScrollToTopButton";
import { TitleAndBackBtn } from "./TitleAndBackBtn";

declare const window: Window &
    typeof globalThis & {
        prerenderReady: any;
    };

type Props = {};

const getMaxWidthForDisplayFormat = (displayFormat: DisplayFormat) => {
    switch (displayFormat) {
        case DisplayFormat.SMALL:
            return "40%";
        case DisplayFormat.MEDIUM:
            return "60%";
        case DisplayFormat.LARGE:
            return "80%";
        case DisplayFormat.XLARGE:
            return "100%";
        default:
            return "100%";
    }
};

export const WorkDetails: React.FC<Props> = () => {
    const { setTitle } = React.useContext(CurrentPageContext);
    const [work, setWork] = React.useState<Work>();
    const [nextWork, setNextWork] = React.useState<Work>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [backgroundPhotoUrl, setBackgroundPhotoUrl] = React.useState<string>();
    const [nextWorkBackgroundUrl, setNextWorkBackgroundUrl] = React.useState<string>();
    const { enqueueSnackbar } = useSnackbar();
    const { width: windowWidth } = useWindowSize();

    let navigate: NavigateFunction = useNavigate();
    let { workUrl } = useParams();

    const handleClientStateChange = (newState: any) => {
        setTimeout(() => {
            window.prerenderReady = true;
        }, 1000);
    };

    const initWork = async (url: string): Promise<void> => {
        setIsLoading(true);

        await WorkService.workControllerWorkDetails(url)
            .then(res => {
                setTitle(`work | ${res.work.title}`);
                setWork(res.work);
                setNextWork(res.nextWork);
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 20000,
                });
                throw err;
            })
            .finally(() => {
                setTimeout(() => setIsLoading(false), 500);
            });
    };

    useEffect(() => {
        if (!workUrl) {
            console.log("missing workUrl");
            navigate("/404");
            return;
        }

        const f = async () => {
            await initWork(workUrl!);
        };
        f();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workUrl]);

    useEffect(() => {
        if (work) {
            let i = 0;
            if (work.photos) {
                setBackgroundPhotoUrl(configInstance.urls.storage + getPathBasedOnWindowWidth(work.mainPhoto!.path, windowWidth));
                setInterval(() => {
                    const photo = work.photos![i];
                    setBackgroundPhotoUrl(configInstance.urls.storage + getPathBasedOnWindowWidth(photo.path, windowWidth));

                    if (i === work.photos!.length - 1) {
                        i = 0;
                    } else {
                        i++;
                    }
                }, 500);
            } else {
                console.log("work.photos is null, no background photos");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [work]);

    useEffect(() => {
        if (nextWork) {
            let i = 0;
            if (nextWork.photos && nextWork.mainPhoto) {
                setNextWorkBackgroundUrl(configInstance.urls.storage + getPathBasedOnWindowWidth(nextWork.mainPhoto!.path, windowWidth));
                setInterval(() => {
                    const photo = nextWork.photos![i];
                    setNextWorkBackgroundUrl(configInstance.urls.storage + getPathBasedOnWindowWidth(photo.path, windowWidth));

                    if (i === nextWork.photos!.length - 1) {
                        i = 0;
                    } else {
                        i++;
                    }
                }, 300);
            } else {
                console.log("nextWork.photos or nextWork.mainPhoto is null, no background photos");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextWork]);

    return (
        <React.Fragment>
            {/* <Loading isLoading={isLoading} /> */}
            <ScrollToTopButton />

            {work?.mainPhoto ? (
                <Helmet onChangeClientState={handleClientStateChange}>
                    <meta property="fb:app_id" content="1234567890" />
                    <meta property="og:type" content="article" />
                    <meta property="og:url" content="https://www.gerdwaliszewski.eu" />
                    <meta property="og:site_name" content="GERD WALISZEWSKI" />
                    <meta property="og:description" content="PHOTOGRAPHER" />
                    <meta property="og:image" content={configInstance.urls.storage + getPathSm(work.mainPhoto.path, 1000)} />
                    <meta property="og:title" content={work?.title} />
                </Helmet>
            ) : (
                <></>
            )}

            {work ? (
                <>
                    <TitleAndBackBtn
                        position="fixed"
                        titleEl={
                            <Grid item>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: "50px!important;",
                                        maxWidth: "50vw",
                                        [publicTheme.breakpoints.down("lg")]: {
                                            fontSize: "44px!important;",
                                        },
                                        [publicTheme.breakpoints.down("sm")]: {
                                            width: "100%",
                                            maxWidth: "100vw",
                                        },
                                    }}
                                >
                                    {work.title}
                                </Typography>
                            </Grid>
                        }
                        backLink="/"
                    />
                    <Grid container sx={{ ml: "45vw", minHeight: "100vh", [publicTheme.breakpoints.down("lg")]: { ml: 1, mr: 1 } }}>
                        <Grid
                            item
                            sx={{
                                zIndex: 100,
                                width: `calc(55vw - ${publicTheme.spacing(4)})`,
                                mt: 32,
                                [publicTheme.breakpoints.down("lg")]: { width: `calc(100% - 16px)` },
                            }}
                        >
                            <Grid
                                container
                                sx={{
                                    justifyContent: "space-between",
                                    [publicTheme.breakpoints.down("sm")]: {
                                        flexDirection: "column",
                                    },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        width: `calc(50% - ${publicTheme.spacing(2)})`,
                                        [publicTheme.breakpoints.down("sm")]: {
                                            width: `calc(100% - 8px)`,
                                            mt: "30vh",
                                            mb: 2,
                                        },
                                    }}
                                >
                                    {work.descriptionLeft ? htmlReactParser(work.descriptionLeft) : null}
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: `calc(50% - ${publicTheme.spacing(2)})`,
                                        [publicTheme.breakpoints.down("sm")]: {
                                            width: `calc(100% - 8px)`,
                                        },
                                    }}
                                >
                                    {work.descriptionRight ? htmlReactParser(work.descriptionRight) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ justifyContent: "end" }}>
                        <Grid
                            item
                            sx={{
                                position: "absolute",
                                width: "70%",
                                height: "100vh",
                                mb: 14,
                                right: publicTheme.spacing(2),
                                top: publicTheme.spacing(2),
                                textAlign: "right",
                                zIndex: 90,
                                opacity: 0.5,
                                [publicTheme.breakpoints.down("lg")]: { width: `calc(100% - 32px)` },
                                [publicTheme.breakpoints.down("sm")]: { right: "8px", width: "calc(100% - 16px)" },
                            }}
                        >
                            <Box
                                component="img"
                                src={backgroundPhotoUrl}
                                alt={backgroundPhotoUrl + "_alt"}
                                sx={{
                                    height: "auto",
                                    width: "auto",
                                    maxWidth: "100%",
                                    opacity: 0.3,
                                    maxHeight: `calc(100vh - ${publicTheme.spacing(4)})`,
                                    [publicTheme.breakpoints.down("lg")]: {
                                        margin: 0,
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    },
                                }}
                            ></Box>
                        </Grid>
                        {work.photos?.map((photo, i) => (
                            <React.Fragment key={i}>
                                <Grid
                                    item
                                    sx={{
                                        mt: 6,
                                        textAlign: "right",
                                        [publicTheme.breakpoints.down("sm")]: {
                                            position: "relative",
                                            right: "8px",
                                        },
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={configInstance.urls.storage + getPathBasedOnWindowWidth(photo.path, windowWidth)}
                                        alt={photo.filename + "_alt"}
                                        sx={{
                                            // maxHeight: `calc(100vh - ${publicTheme.spacing(8)})`,
                                            width: "auto",
                                            maxWidth: "55vw",
                                            [publicTheme.breakpoints.up("sm")]: {
                                                maxWidth: getMaxWidthForDisplayFormat(photo.displayFormat || DisplayFormat.XLARGE),
                                            },
                                            [publicTheme.breakpoints.down("sm")]: {
                                                maxWidth: "calc(100vw - 16px)",
                                            },
                                        }}
                                    ></Box>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        textAlign: "right",
                                        width: "100%",
                                        [publicTheme.breakpoints.down("sm")]: {
                                            mr: 1,
                                        },
                                    }}
                                >
                                    <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                                        {photo.title ? photo.title : ""}
                                    </Typography>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                    {nextWork ? (
                        <Grid container sx={{ justifyContent: "end", alignContent: "center", height: "90vh" }}>
                            <Grid item sx={{ width: "35vw" }}>
                                <Grid container sx={{ justifyContent: "end", position: "relative" }}>
                                    <Grid
                                        item
                                        sx={{
                                            zIndex: 1060,
                                            "&:hover": {
                                                "+ div": {
                                                    opacity: 0.4,
                                                },
                                            },
                                        }}
                                    >
                                        <Link to={`/work/${nextWork.url}`}>
                                            <Grid container sx={{ justifyContent: "end" }}>
                                                <Grid item>
                                                    <Box
                                                        component="img"
                                                        src={configInstance.urls.frontend + "/images/scrollTop.png"}
                                                        alt={""}
                                                        sx={{
                                                            rotate: "90deg",
                                                            height: "50px",
                                                            width: "auto",
                                                            mr: 2,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item sx={{ width: "100%", mt: 4 }}>
                                                    <Typography variant="h2" sx={{ textAlign: "end" }}>
                                                        {nextWork.title}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </Grid>

                                    <Grid
                                        item
                                        sx={{
                                            opacity: 0,
                                            transition: publicTheme.transitions.create("opacity", {
                                                easing: publicTheme.transitions.easing.sharp,
                                                duration: publicTheme.transitions.duration.enteringScreen,
                                            }),
                                            [publicTheme.breakpoints.down("lg")]: { opacity: 0.4 },
                                        }}
                                    >
                                        <Box
                                            id="nextwork_img"
                                            component="img"
                                            src={nextWorkBackgroundUrl}
                                            alt="social_alt"
                                            sx={{
                                                opacity: nextWorkBackgroundUrl ? 1 : 0,
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                height: "50vh",
                                                width: "auto",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid container sx={{ mt: 8 }}>
                        <Grid item sx={{ ml: 7, mb: 0.6 }}>
                            <Link to="/imprint">
                                <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                                    IMPRINT & DATA PROTECTION
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <CircularProgress />
            )}
        </React.Fragment>
    );
};
