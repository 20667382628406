import { Box, Grid, Slide, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { configInstance } from "../../config";
import { MetaContext } from "../../context/MetaContext";
import { getPathBasedOnWindowWidth } from "../../helpers/getPathBasedOnWindowWidth";
import { publicTheme } from "../App";
import { Location } from "./Location";

type Props = {};

export const Menu: React.FC<Props> = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [socialButtonPhotoUrl, setSocialButtonPhotoUrl] = React.useState<string>();
    const { metaWithPhotos } = React.useContext(MetaContext);
    const { width: windowWidth } = useWindowSize();
    const [slideshowInterval, setSlideshowInterval] = React.useState<NodeJS.Timer>();
    const navigate = useNavigate();

    useEffect(() => {
        clearInterval(slideshowInterval);
        let i = 0;

        const interval = setInterval(() => {
            if (metaWithPhotos?.photos) {
                const photo = metaWithPhotos.photos[i];
                setSocialButtonPhotoUrl(configInstance.urls.storage + getPathBasedOnWindowWidth(photo.path, windowWidth));

                if (i === metaWithPhotos.photos.length - 1) {
                    i = 0;
                } else {
                    i++;
                }
            }
        }, 300);

        setSlideshowInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaWithPhotos]);

    useEffect(() => {
        if (metaWithPhotos?.meta.isSiteDisabled) {
            navigate("/site-disabled");
        }
    }, [metaWithPhotos]);

    const toggleMenu = () => {
        if (isOpen) {
            setIsOpen(false);

            // body
            document.body.style.overflow = "unset";
            document.body.style.overflowX = "hidden";

            // html
            const htmlTags = document.getElementsByTagName("html");
            for (var i = 0; i < htmlTags.length; i++) {
                htmlTags[i].style.overflow = "unset";
                htmlTags[i].style.overflowX = "hidden";
            }
        } else {
            setIsOpen(true);

            // body
            document.body.style.overflow = "hidden";

            // html
            const htmlTags = document.getElementsByTagName("html");
            for (var i = 0; i < htmlTags.length; i++) {
                htmlTags[i].style.overflow = "hidden";
            }
        }
    };

    return metaWithPhotos?.meta.isSiteDisabled ? null : (
        <React.Fragment>
            {/* toggler button */}
            <Grid
                container
                sx={{
                    zIndex: 1100,
                    mr: 4,
                    mt: 2,
                    width: "50%",
                    position: "fixed",
                    justifyContent: "end",
                    right: 0,
                    [publicTheme.breakpoints.down("sm")]: {
                        mr: 0,
                        mt: 0,
                        top: "12px",
                        right: "6px",
                        // right: "6px",
                    },
                }}
            >
                <Grid item>
                    <Box sx={{ position: "relative", width: "fit-content" }}>
                        <Typography variant="h4" onClick={toggleMenu} className="a-strike hover-pointer" sx={{ lineHeight: 0.85 }}>
                            {isOpen ? "CLOSE" : "MENU"}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            {/* content */}
            <Slide direction="down" in={isOpen} timeout={300} mountOnEnter>
                <Grid
                    id="menuBackground"
                    container
                    sx={{
                        // zIndex: isOpen ? 1099 : -100,
                        zIndex: 1099,
                        height: "100dvh",
                        width: "100vw",
                        top: 0,
                        backgroundColor: publicTheme.palette.background.default,
                        // opacity: isOpen ? 1 : 0,
                        position: "fixed",
                    }}
                >
                    <Grid item sx={{ width: `calc(100vw - ${publicTheme.spacing(4)})` }}>
                        <Grid
                            id="menuContentWrapper"
                            container
                            sx={{
                                mt: 4,
                                ml: 2,
                                mr: 4,
                                height: `calc(100vh - ${publicTheme.spacing(4.5)})`,
                                justifyContent: "space-between",
                                alignContent: "space-between",

                                [publicTheme.breakpoints.down("sm")]: {
                                    mt: 2,
                                    ml: "10px",
                                },
                            }}
                        >
                            <Location />
                            <Grid
                                item
                                sx={{
                                    height: "fit-content",
                                    [publicTheme.breakpoints.down("lg")]: {
                                        marginTop: "60px",
                                    },
                                }}
                            >
                                <Box sx={{ position: "relative", width: "fit-content" }}>
                                    <Link onClick={toggleMenu} to="/">
                                        <Typography
                                            className="a-strike"
                                            variant="h2"
                                            sx={{
                                                [publicTheme.breakpoints.down("sm")]: {
                                                    fontSize: "40px!important;",
                                                },
                                            }}
                                        >
                                            WORKS
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ position: "relative", width: "fit-content" }}>
                                    <Link onClick={toggleMenu} to="/about">
                                        <Typography
                                            className="a-strike"
                                            variant="h2"
                                            sx={{
                                                [publicTheme.breakpoints.down("sm")]: {
                                                    fontSize: "40px!important;",
                                                },
                                            }}
                                        >
                                            ABOUT
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ position: "relative", width: "fit-content" }}>
                                    <Link onClick={toggleMenu} to="/publications">
                                        <Typography
                                            className="a-strike"
                                            variant="h2"
                                            sx={{
                                                [publicTheme.breakpoints.down("sm")]: {
                                                    fontSize: "40px!important;",
                                                },
                                            }}
                                        >
                                            PUBLICATIONS
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ position: "relative", width: "fit-content" }}>
                                    <Link onClick={toggleMenu} to="/exhibitions">
                                        <Typography
                                            className="a-strike"
                                            variant="h2"
                                            sx={{
                                                [publicTheme.breakpoints.down("sm")]: {
                                                    fontSize: "40px!important;",
                                                },
                                            }}
                                        >
                                            EXHIBITIONS
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ position: "relative", width: "fit-content" }}>
                                    <Link onClick={toggleMenu} to="/contact">
                                        <Typography
                                            className="a-strike"
                                            variant="h2"
                                            sx={{
                                                [publicTheme.breakpoints.down("sm")]: {
                                                    fontSize: "40px!important;",
                                                },
                                            }}
                                        >
                                            CONTACT
                                        </Typography>
                                    </Link>
                                </Box>
                            </Grid>

                            {/* absolute elements */}
                            <Grid
                                item
                                sx={{
                                    position: "absolute",
                                    bottom: publicTheme.spacing(2),
                                    left: publicTheme.spacing(2),
                                }}
                            >
                                <Link onClick={toggleMenu} to="/imprint">
                                    <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                                        IMPRINT & DATA PROTECTION
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    position: "absolute",
                                    zIndex: 1101,
                                    bottom: 8,
                                    right: "48px",
                                    "&:hover": {
                                        "+ div": {
                                            opacity: 0.4,
                                        },
                                    },
                                    [publicTheme.breakpoints.down("sm")]: {
                                        right: "12px",
                                    },
                                }}
                            >
                                <Link
                                    to={metaWithPhotos?.meta.menuSocialUrl ?? ""}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="a-strike"
                                >
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            [publicTheme.breakpoints.down("sm")]: {
                                                fontSize: "40px!important;",
                                            },
                                        }}
                                    >
                                        {metaWithPhotos?.meta.menuSocialText}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    [publicTheme.breakpoints.down("lg")]: {
                                        opacity: 0.4,
                                    },
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    opacity: 0,
                                    zIndex: -1,
                                    transition: publicTheme.transitions.create("opacity", {
                                        easing: publicTheme.transitions.easing.sharp,
                                        duration: publicTheme.transitions.duration.enteringScreen,
                                    }),
                                }}
                            >
                                <Box
                                    id="social_img"
                                    component="img"
                                    loading="lazy"
                                    src={socialButtonPhotoUrl}
                                    alt="social_alt"
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        height: "auto",
                                        width: "auto",
                                        maxWidth: "50vw",
                                        maxHeight: `calc(50vw - ${publicTheme.spacing(4)})`,
                                        zIndex: 1100,
                                    }}
                                ></Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Slide>
        </React.Fragment>
    );
};
