import { throttle } from "lodash";
import { useEffect } from "react";

type CallbackFunction = () => void;

const useThrottledScroll = (callback: CallbackFunction, delay: number) => {
    const throttledCallback = throttle(callback, delay);

    useEffect(() => {
        window.addEventListener("scroll", throttledCallback);
        return () => {
            window.removeEventListener("scroll", throttledCallback);
        };
    }, [throttledCallback]);
};

export default useThrottledScroll;
