import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { createTheme } from "@mui/material";
import ReactGA from "react-ga4";
import { UserRole } from "../types";
import { NotFound } from "./NotFound";
import { Public } from "./Public";
import { RequireAuth } from "./RequireAuth";
import { Dashboard } from "./admin/Dashboard";
import { Exhibitions } from "./admin/Exhibitions";
import { Login } from "./admin/Login";
import { Settings } from "./admin/Settings";
import { UserSettings } from "./admin/UserSettings";
import { ExhibitionEdit } from "./admin/exhibitions/ExhibitionEdit";
import { ExhibitionList } from "./admin/exhibitions/ExhibitionList";
import { PublicationEdit } from "./admin/publication/PublicationEdit";
import { PublicationList } from "./admin/publication/PublicationList";
import { WorkEdit } from "./admin/works/WorkEdit";
import { WorkList } from "./admin/works/WorkList";
import { About } from "./public/About";
import { Contact } from "./public/Contact";
import { ExhibitionsList } from "./public/ExhibitionsList";
import { Imprint } from "./public/Imprint";
import { PublicationsList } from "./public/PublicationsList";
import { WebDesign } from "./public/WebDesign";
import { WorkDetails } from "./public/WorkDetails";
import { Works } from "./public/Works";
import { SiteDisabled } from "./public/SiteDisabled";

ReactGA.initialize("G-BK5P446KPY");

export const adminTheme = createTheme({
    palette: {},
});

export const publicTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#39ff14",
        },
        secondary: {
            main: "#39FF14",
        },
        divider: "#39ff14",
        background: {
            default: "#0a0d21",
            paper: "#0a0d21",
        },
    },
    shape: {
        borderRadius: 0,
    },
    transitions: {
        duration: {
            enteringScreen: 100,
        },
    },
});

adminTheme.transitions.create(["background-color"], {
    easing: "ease-in-out",
    duration: "0.3s",
});

const App: React.FC = () => {
    return (
        <Routes>
            {/* admin */}

            <Route path="/login" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<Login />} />} />
            <Route path="/admin" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<Dashboard />} />} />
            <Route path="/admin/dashboard" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<Dashboard />} />} />
            <Route path="/admin/works" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<WorkList />} />} />
            <Route path="/admin/works/:id" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<WorkEdit />} />} />
            <Route path="/admin/exhibitions" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<ExhibitionList />} />} />
            <Route
                path="/admin/exhibitions/:id"
                element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<ExhibitionEdit />} />}
            />
            <Route path="/admin/publications" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<PublicationList />} />} />
            <Route
                path="/admin/publications/:id"
                element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<PublicationEdit />} />}
            />
            <Route path="/admin/exhibitions" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<Exhibitions />} />} />
            <Route path="/admin/settings" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<Settings />} />} />
            <Route path="/admin/user-settings" element={<RequireAuth requiredRoles={[UserRole.ADMIN]} component={<UserSettings />} />} />

            {/* public */}
            <Route path="/" element={<Public component={<Works />} />} />
            <Route path="/work/:workUrl" element={<Public component={<WorkDetails />} />} />
            <Route path="/exhibitions" element={<Public component={<ExhibitionsList />} />} />
            <Route path="/publications" element={<Public component={<PublicationsList />} />} />
            <Route path="/about" element={<Public component={<About />} />} />
            <Route path="/contact" element={<Public component={<Contact />} />} />
            <Route path="/imprint" element={<Public component={<Imprint />} />} />
            <Route path="/webdesign" element={<Public component={<WebDesign />} />} />
            <Route path="/404" element={<Public component={<NotFound />} />} />
            <Route path="*" element={<Public component={<NotFound />} />} />
            <Route path="/site-disabled" element={<Public component={<SiteDisabled />} />} />
        </Routes>
    );
};

export default App;
