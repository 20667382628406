import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CurrentPageContext } from "../context/CurrentPageContext";

type Props = {};

export const NotFound: React.FC<Props> = () => {
    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);

    useEffect(() => {
        setTitle("not found");
        setNavBarKey(undefined);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container>
            <Box
                sx={{
                    m: 2,
                    display: "flex",
                    flexFlow: "column",
                }}
            >
                <Typography variant="h2">404 Not Found</Typography>
                <Typography variant="h3">The page you're looking for doesn't exist.</Typography>
                <Divider sx={{ my: 2 }} />
                <Link to="/">
                    <Button variant="outlined" size="large" sx={{ width: "fit-content", justifyContent: "flex-start" }}>
                        GO HOME
                    </Button>
                </Link>
            </Box>
        </Grid>
    );
};
