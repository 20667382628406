import { Grow, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { useSnackbar } from "notistack";
import React from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";
import { Copyright } from "./Copyright";
import { LoginLocalInput } from "../../client/generated";
import { AuthContext } from "../../context/AuthContext";

type Props = {};

export const Login: React.FC<Props> = () => {
    let navigate: NavigateFunction = useNavigate();
    const { login, user } = React.useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const [formData, setFormData] = React.useState<LoginLocalInput>({ username: "", password: "" });

    const onLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        await login(formData.username, formData.password);
        navigate("/admin/dashboard");
        window.location.reload();
    };

    const onFormFieldChange = (event: any) => {
        setFormData({
            ...formData,
            [event.target.name as keyof LoginLocalInput]: event.target.value,
        });
    };

    const onFormSubmitError = (e: any) => {
        enqueueSnackbar(<Typography variant="subtitle1">{JSON.stringify(e)}</Typography>, {
            variant: "error",
            autoHideDuration: 2000,
        });
    };

    if (user) {
        return <Navigate to="/admin/dashboard" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Grow in>
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <ValidatorForm onSubmit={onLogin} onError={onFormSubmitError} sx={{ mt: 1 }}>
                        <TextValidator
                            margin="normal"
                            fullWidth
                            name={"username" as keyof LoginLocalInput}
                            label="Username"
                            variant="outlined"
                            onChange={onFormFieldChange}
                            value={formData.username}
                            validators={["required"]}
                            errorMessages={["Field is required"]}
                        />
                        <TextValidator
                            type="password"
                            margin="normal"
                            fullWidth
                            name={"password" as keyof LoginLocalInput}
                            label="Password"
                            variant="outlined"
                            onChange={onFormFieldChange}
                            value={formData.password}
                            validators={["required"]}
                            errorMessages={["Field is required"]}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Log in
                        </Button>
                    </ValidatorForm>
                    <Copyright />
                </Box>
            </Grow>
        </Container>
    );
};
