import { Grid, Link, Typography } from "@mui/material";

export function Copyright(props: any) {
    return (
        <Grid>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {"© "}
                {new Date().getFullYear() + " "}
                <Link color="inherit" href="mailto:dmszanowski@icloud.com">
                    Dawid Mszanowski
                </Link>
            </Typography>
        </Grid>
    );
}
