import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Divider, Fade, FormHelperText, Grid, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { UserService } from "../../client/generated";
import { CurrentPageContext } from "../../context/CurrentPageContext";

type Props = {};

export const UserSettings: React.FC<Props> = () => {
    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);
    const { enqueueSnackbar } = useSnackbar();

    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<{
        username: string;
        firstName: string;
        lastName: string;
        roles: string[];
        password: string;
        passwordConfirm: string;
    }>({ username: "", firstName: "", lastName: "", roles: [], password: "", passwordConfirm: "" });
    const [saveBtnDisabled, setSaveBtnDisabled] = React.useState<boolean>(false);

    const formHasErrors = (): boolean => {
        let formHasErrors = false;
        if (passwordFieldsMatchError()) {
            formHasErrors = true;
        }

        if (formHasErrors) {
            enqueueSnackbar(<Typography variant="subtitle1">Form is invalid, see the fields for details.</Typography>, {
                variant: "error",
                autoHideDuration: 4000,
            });
        }
        return formHasErrors;
    };

    const passwordFieldsMatchError = (): boolean | string => {
        if (formData.password === "" || formData.passwordConfirm === "") {
            return false;
        }
        if (formData.password !== formData.passwordConfirm) {
            return "Passwords are not the same.";
        }
        return false;
    };

    useEffect(() => {
        setTitle("admin |  user settings");
        setNavBarKey(undefined);

        UserService.userControllerUserDetails()
            .then((user: { username: any; roles: any }) => {
                setHasUnsavedChanges(false);
                setFormData({
                    ...formData,
                    username: user.username,
                    roles: user.roles,
                });
            })
            .catch((err: { message: any }) => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setSaveBtnDisabled(true);
        if (formHasErrors()) {
            setTimeout(() => setSaveBtnDisabled(false), 4000);
            return;
        }

        UserService.userControllerUserUpdate({
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
        })
            .then((user: any) => {
                setFormData({ ...formData, password: "", passwordConfirm: "" });
                setTimeout(() => setSaveBtnDisabled(false), 2000);
                enqueueSnackbar(<Typography variant="subtitle1">Saved.</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch((err: { message: any }) => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });
    };

    return (
        <>
            {hasUnsavedChanges ? (
                <Snackbar open={true} anchorOrigin={{ vertical: "top", horizontal: "right" }} sx={{ mr: 22, mt: -2 }}>
                    <Alert severity="warning" variant="filled">
                        Unsaved changes
                    </Alert>
                </Snackbar>
            ) : null}
            <Fade in>
                <Paper component="form" onSubmit={handleFormSubmit}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            <TextField name="username" label="Username" variant="outlined" value={formData.username} disabled fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 1 }} />
                            <FormHelperText>
                                Fill in the fields below only if you want to change your password. If you leave the fields blank, password
                                will not be changed.
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!passwordFieldsMatchError()}
                                helperText={passwordFieldsMatchError()}
                                name="password"
                                label="Password"
                                variant="outlined"
                                onChange={e => {
                                    setFormData({ ...formData, [e.target.name]: e.target.value });
                                    setHasUnsavedChanges(true);
                                }}
                                type="password"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={!!passwordFieldsMatchError()}
                                helperText={passwordFieldsMatchError()}
                                name="passwordConfirm"
                                label="Confirm password"
                                variant="outlined"
                                onChange={e => {
                                    setFormData({ ...formData, [e.target.name]: e.target.value });
                                    setHasUnsavedChanges(true);
                                }}
                                type="password"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ mb: 2 }} />
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <LoadingButton
                                    disabled={saveBtnDisabled}
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    color="success"
                                    sx={{ mr: 1 }}
                                >
                                    Save
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
        </>
    );
};
