import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    Grow,
    Paper,
    Snackbar,
    Switch,
    Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { EditExhibitionInput, Exhibition, ExhibitionService, Photo } from "../../../client/generated";
import { configInstance } from "../../../config";
import { CurrentPageContext } from "../../../context/CurrentPageContext";
import { OrEmptyStrings } from "../../../types";
import { adminTheme } from "../../App";
import { PhotoPreviewBackdrop, PhotoPreviewBackdropState } from "../PhotoPreviewBackdrop";

export type PhotoFilePair = {
    file: File;
    title: string;
    isVisibleOnBgButtons: boolean;
    photo?: Photo;
};

const defaultEditExhibitionFormData: Partial<OrEmptyStrings<EditExhibitionInput>> = {
    isActive: true,
    url: "",
    title: "",
    photo: undefined,
};

async function photoEntityToPhotoFile(photo: Photo): Promise<File> {
    const res = await axios.get(configInstance.urls.storage + photo.path, {
        responseType: "blob",
    });
    const file = new File([res.data], photo.filename);
    return file;
}

type Props = {};

export const ExhibitionEdit: React.FC<Props> = () => {
    let { id } = useParams();
    let navigate: NavigateFunction = useNavigate();

    const { setTitle, setNavBarKey } = React.useContext(CurrentPageContext);
    const { enqueueSnackbar } = useSnackbar();

    const [tinyEditorLoading, setTinyEditorLoading] = React.useState<boolean>(true);
    const [tinyEditorIgnoreUnsavedChanges, setTinyEditorIgnoreUnsavedChanges] = React.useState<{
        descriptionLeft: boolean;
        descriptionRight: boolean;
    }>({ descriptionLeft: true, descriptionRight: true });
    const [parsedParamId, setParsedParamId] = React.useState<string>();
    const [exhibition, setExhibition] = React.useState<Exhibition>();
    const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);
    const [isSaveBtnLoading, setIsSaveBtnLoading] = React.useState<boolean>(false);
    const [editExhibitionFormData, setEditExhibitionFormData] =
        React.useState<Partial<OrEmptyStrings<EditExhibitionInput>>>(defaultEditExhibitionFormData);
    const [photosLoading, setPhotosLoading] = React.useState<boolean>(true);
    const [photoPreviewBackdrop, setPhotoPreviewBackdrop] = React.useState<PhotoPreviewBackdropState>({
        src: "",
        visible: false,
    });

    const onEditExhibitionFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setIsSaveBtnLoading(true);
        ExhibitionService.exhibitionControllerExhibitionEdit({
            id: parsedParamId!,
            isActive: editExhibitionFormData.isActive as boolean,
            url: editExhibitionFormData.url as string,
            title: editExhibitionFormData.title as string,
            photo: editExhibitionFormData.photo as File,
        })
            .then(() => {
                setHasUnsavedChanges(false);
                navigate(0); // reload
                enqueueSnackbar(<Typography variant="subtitle1">Exhibition saved</Typography>, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            })
            .finally(() => {
                setTimeout(() => setIsSaveBtnLoading(false), 2000);
            });
    };

    const onEditExhibitionFormSubmitError = (e: any) => {
        console.error(e);
        enqueueSnackbar(<Typography variant="subtitle1">Some error occured, see the browser console.</Typography>, {
            variant: "error",
            autoHideDuration: 2000,
        });
    };

    const onEditExhibitionFormFieldUpdate = (event: any) => {
        if (!event.tinyEditorIgnoreUnsavedChanges) {
            setHasUnsavedChanges(true);
        }

        setEditExhibitionFormData({
            ...editExhibitionFormData,
            [event.target.name as keyof EditExhibitionInput]: event.target.value,
        });
    };

    const onEditExhibitionFormSwitchUpdate = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setHasUnsavedChanges(true);
        setEditExhibitionFormData({
            ...editExhibitionFormData,
            [event.target.name as keyof EditExhibitionInput]: checked,
        });
    };

    const onEditExhibitionFormFileInputUpdate = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (event) {
            setHasUnsavedChanges(true);

            const photoFile = event.target.files ? Array.from(event.target.files)[0] : null;
            if (photoFile) {
                setEditExhibitionFormData({ ...editExhibitionFormData, photo: photoFile });
            }
        }
    };

    useEffect(() => {
        setTitle(`admin | exhibition edit`);
        setNavBarKey("exhibition");

        if (id) {
            setParsedParamId(id);
        } else {
            enqueueSnackbar(<Typography variant="subtitle1">Invalid parameter {id}.</Typography>, {
                variant: "error",
                autoHideDuration: 4000,
            });
            return navigate("/admin/exhibitions");
        }

        ExhibitionService.exhibitionControllerExhibitionDetails(id!)
            .then(exhibitionWithNext => {
                setExhibition(exhibitionWithNext.exhibition);
                setEditExhibitionFormData({
                    isActive: exhibitionWithNext.exhibition.isActive,
                    url: exhibitionWithNext.exhibition.url ?? "",
                    title: exhibitionWithNext.exhibition.title ?? "",
                    // files and some other file related fields are set in another useEffect
                });
            })
            .catch(err => {
                enqueueSnackbar(<Typography variant="subtitle1">{err.message}</Typography>, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                throw err;
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (exhibition?.photo) {
            photoEntityToPhotoFile(exhibition.photo).then(photoFile => {
                setEditExhibitionFormData({ ...editExhibitionFormData, photo: photoFile });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exhibition]);

    return (
        <>
            <PhotoPreviewBackdrop state={photoPreviewBackdrop} setState={setPhotoPreviewBackdrop} />
            {hasUnsavedChanges ? (
                <Snackbar open={true} anchorOrigin={{ vertical: "top", horizontal: "right" }} sx={{ mr: 22, mt: -2 }}>
                    <Alert severity="warning" variant="filled">
                        Unsaved changes
                    </Alert>
                </Snackbar>
            ) : null}

            <Grid container sx={{ justifyContent: "center" }}>
                {exhibition ? (
                    <Grow in>
                        <Grid item xs={12}>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <ValidatorForm
                                    onSubmit={onEditExhibitionFormSubmit}
                                    onError={onEditExhibitionFormSubmitError}
                                    sx={{ display: "flex", flexDirection: "column", gap: 22 }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Typography variant="h6">General</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                name={"title" as keyof EditExhibitionInput}
                                                label="Title"
                                                variant="outlined"
                                                onChange={onEditExhibitionFormFieldUpdate}
                                                value={editExhibitionFormData.title}
                                                validators={["required", "maxStringLength:255"]}
                                                errorMessages={["Field is required", "Max length is 255 characters"]}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                name={"url" as keyof EditExhibitionInput}
                                                label="Url"
                                                variant="outlined"
                                                onChange={onEditExhibitionFormFieldUpdate}
                                                value={editExhibitionFormData.url}
                                                validators={["required"]}
                                                errorMessages={["Field is required"]}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name={"isActive" as keyof EditExhibitionInput}
                                                        checked={!!editExhibitionFormData.isActive}
                                                        onChange={onEditExhibitionFormSwitchUpdate}
                                                    />
                                                }
                                                label="Mark as active"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Photo</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Paper
                                                elevation={1}
                                                sx={{
                                                    backgroundColor: adminTheme.palette.grey[50],
                                                }}
                                            >
                                                <Grid container sx={{ alignItems: "center" }}>
                                                    {editExhibitionFormData.photo ? (
                                                        <Grid
                                                            item
                                                            onClick={() =>
                                                                setPhotoPreviewBackdrop({
                                                                    visible: true,
                                                                    src: URL.createObjectURL(editExhibitionFormData.photo as Blob),
                                                                })
                                                            }
                                                        >
                                                            <Avatar
                                                                className="hoverPointer"
                                                                sx={{ borderRadius: 0, m: 1 }}
                                                                alt=" "
                                                                src={URL.createObjectURL(editExhibitionFormData.photo as Blob)}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        <Grid item sx={{ m: 1, ml: 2 }}>
                                                            <Typography variant="body1">No photo uploaded yet.</Typography>{" "}
                                                        </Grid>
                                                    )}

                                                    <Grid item sx={{ flexGrow: 2 }}>
                                                        <Grid container>
                                                            <Grid item sx={{ width: "100%" }}>
                                                                <Typography variant="body1">
                                                                    {/* {JSON.stringify(photoFilePair)} */}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sx={{ m: 1, mr: 2 }}>
                                                        <Button variant="contained" component="label">
                                                            Upload photo
                                                            <input
                                                                type="file"
                                                                hidden
                                                                accept="image/png, image/gif, image/jpeg"
                                                                onChange={onEditExhibitionFormFileInputUpdate}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Divider sx={{ my: 1 }} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={<SaveIcon />}
                                                    color="success"
                                                    disabled={isSaveBtnLoading}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Paper>
                        </Grid>
                    </Grow>
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        </>
    );
};
