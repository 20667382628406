import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type Props = {
    title: string;
    content: string;
    isOpen: boolean;
    onClose: () => any;
    onNoButtonClick: () => any;
    onYesButtonClick: () => any;
};

export function ConfirmationDialog(props: Props) {
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pb: 2.5, px: 3, justifyContent: "flex-start" }}>
                <Button startIcon={<DoneIcon />} variant="contained" onClick={props.onYesButtonClick} color="success">
                    Yes
                </Button>
                <Button
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    onClick={props.onNoButtonClick}
                    autoFocus
                    color="error"
                >
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
}
