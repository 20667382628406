import { Box, Fade, Grid, Typography } from "@mui/material";
import htmlReactParser from "html-react-parser";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { configInstance } from "../../config";
import { CurrentPageContext } from "../../context/CurrentPageContext";
import { MetaContext } from "../../context/MetaContext";
import { getPathBasedOnWindowWidth } from "../../helpers/getPathBasedOnWindowWidth";
import { publicTheme } from "../App";
import { TitleAndBackBtn } from "./TitleAndBackBtn";

type Props = {};

export const About: React.FC<Props> = () => {
    const { setTitle } = React.useContext(CurrentPageContext);
    const { metaWithPhotos } = React.useContext(MetaContext);
    const { width: windowWidth } = useWindowSize();

    useEffect(() => {
        setTitle("about");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TitleAndBackBtn
                position="fixed"
                titleEl={
                    <Grid item sx={{ display: "flex", alignContent: "center", flexWrap: "wrap" }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: "50px!important;",
                                [publicTheme.breakpoints.down("lg")]: {
                                    fontSize: "44px!important;",
                                },
                            }}
                        >
                            ABOUT
                        </Typography>
                    </Grid>
                }
                backLink="/"
            />
            <Grid
                container
                sx={{
                    left: "30vw",
                    mt: `calc(100vh - 150px)`,
                    position: "relative",
                    [publicTheme.breakpoints.down("sm")]: {
                        maxWidth: "100vw",
                        left: "8px",
                    },
                }}
            >
                <Grid
                    item
                    className="about-text"
                    sx={{
                        zIndex: 100,
                        width: `calc(100% - 33vw)`,
                        [publicTheme.breakpoints.down("sm")]: {
                            width: `calc(100% - 16vw)`,
                        },
                    }}
                >
                    {metaWithPhotos?.meta.aboutText ? htmlReactParser(metaWithPhotos.meta.aboutText) : null}
                </Grid>
                {metaWithPhotos?.meta.aboutPhoto ? (
                    <>
                        <Grid
                            item
                            sx={{
                                position: "fixed",
                                bottom: `8px`,
                                [publicTheme.breakpoints.down("lg")]: {
                                    top: "130px",
                                },
                            }}
                        >
                            <Box
                                component="img"
                                src={
                                    configInstance.urls.storage +
                                    getPathBasedOnWindowWidth(metaWithPhotos.meta.aboutPhoto.path, windowWidth)
                                }
                                alt={metaWithPhotos.meta.aboutPhoto.filename + "_alt"}
                                sx={{
                                    maxHeight: "75vh",
                                    width: "auto",
                                    maxWidth: "100%",
                                    opacity: "0.6",
                                }}
                            ></Box>
                            <Typography variant="h6" sx={{ lineHeight: 0.85, textAlign: "right", opacity: 0.5 }}>
                                {metaWithPhotos.meta.aboutShotout}
                            </Typography>
                        </Grid>
                    </>
                ) : null}
                <Grid
                    item
                    sx={{
                        mb: 2,
                        width: "100vw",
                        position: "relative",
                        left: `calc(-30vw + ${publicTheme.spacing(1)})`,
                        [publicTheme.breakpoints.down("sm")]: {
                            mt: 2,
                            left: 0,
                        },
                    }}
                >
                    <Link to="/imprint">
                        <Typography variant="h6" sx={{ lineHeight: 0.85 }}>
                            IMPRINT & DATA PROTECTION
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </>
    );
};
