import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { MetaService, MetaWithPhotos } from "../client/generated";

export interface MetaContextInterface {
    isLoading: boolean;
    metaWithPhotos: MetaWithPhotos | undefined | void;
}

export const MetaContextDefaults: MetaContextInterface = {
    isLoading: true,
    metaWithPhotos: undefined,
};

export const MetaContext = React.createContext<MetaContextInterface>(MetaContextDefaults);

export const MetaContextProvider = ({ children }: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { data: metaWithPhotos } = useQuery({
        queryKey: ["meta"],
        queryFn: async () => {
            setIsLoading(true);
            const res = await MetaService.metaControllerGetMeta();
            setTimeout(() => setIsLoading(false), 5000);
            return {
                meta: res.meta,
                photos: _.shuffle(res.photos),
            };
        },
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 5,
        staleTime: 0,
    });

    const value = useMemo(
        () => ({
            metaWithPhotos,
            isLoading,
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [metaWithPhotos, isLoading],
    );
    return <MetaContext.Provider value={value}>{children}</MetaContext.Provider>;
};
